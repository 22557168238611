import _ from 'lodash';
function createColumns(header, labels) {
  let sampleData = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : [];
  return (labels || header).map((columnName, i) => {
    return {
      width: i == 2 ? 600 : 300,
      label: columnName,
      dataKey: header[i],
      numeric: !isNaN(sampleData[i])
    };
  });
}
function toMuiXLSX(datasetDruid, labels) {
  let limitTable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const header = datasetDruid[0];
  const datasetTable = toMui(datasetDruid, labels, limitTable);
  const columns = datasetTable instanceof Error ? [] : datasetTable.columns;
  const rows = datasetTable instanceof Error ? [] : datasetTable.rows;
  const dataset = [header, ...rows.map(row => columns.map(column => row[column.dataKey]))];
  return dataset;
}
/**
 * Por agora foi validado apenas para 3 dimensões.
 *
 * 1 - dimensão texto
 * 2 - dimensão númerico (métrica)
 *
 * @param datasetDruid
 * @returns valor formato para ser exibido.
 */
function toMui(datasetDruid, labels) {
  let limitTable = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 0;
  const header = datasetDruid.shift();
  if (header && header.length === 3) {
    // console.log(_.chain(datasetDruid).groupBy(labels[0]).map(() => {}));
    const output = _.chain(datasetDruid).groupBy(0).map((vals, key) => ({
      id: key,
      [header[0]]: key,
      [header[1]]: vals.reduce((acc, currentValue) => {
        return acc + Number(currentValue[2]);
      }, 0),
      [header[2]]: _.chain(vals).groupBy(1).mapValues(value => {
        return value.reduce((acc, currentValue) => acc + Number(currentValue[2]), 0);
      }).reduce((acc, currentValue, currentKey) => {
        acc[currentValue] = "".concat(currentKey);
        // return acc + `${currentKey} (${currentValue}); `;
        return acc;
      }, {})
      //@ts-ignore
      .reduceRight((acc, currentValue, currentKey) => {
        return acc + "".concat(currentValue, " (").concat(currentKey, "); ");
      }, '').value().slice(0, -2)
    })).value();
    const rowsAll = _.orderBy(output, [header[1]], ['desc']);
    const columns = createColumns(header, labels, datasetDruid[0]);
    let rows;
    if (limitTable > 0) rows = rowsAll.slice(0, limitTable);else rows = rowsAll;
    return {
      columns,
      rows
    };
  } else {
    return new Error('Dataset não suportado.');
  }
}
// function toMuiWithStringAgg(datasetDruid: Array<Array<Number | String>>, labels: string[], indexStringsAgg = 1) {
//     const header: any = datasetDruid.shift();
//     if (header && header.length === 3) {
//         const rows = datasetDruid.map((row: string[], i) => createData(i, header, row));
//         const columns = createColumns(header, labels, datasetDruid[0]);
//         return { columns, rows };
//     } else {
//         return new Error('Dataset não suportado.');
//     }
// }
export default {
  toMui,
  toMuiXLSX
};