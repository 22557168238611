import { XLSXUtil } from '@stt-analise/util';

const exportarDadosFrontendParaXLSX = () => {
    try {
        const viewElement = document.querySelector('td.pvtOutput');
        const table = viewElement?.firstElementChild;
        XLSXUtil.tableToXLSXFile(table);
    } catch (error) {
        console.error('Erro ao exportar os dados: ', error);
    }
};

export default exportarDadosFrontendParaXLSX;
