'use strict';

const Instituicao = require('../modelos/instituicao');
const Wreck = require('@hapi/wreck');
const ServicosMonitorados = {
  _url: null,
  _token: null,
  _timeout: null,
  _dbClient: null,
  /**
   * Categorias e legendas dos serviços monitorados
   */
  _categorias: {
    /* -- Administrativo -- */
    ADMINISTRATIVO_INVALIDACAOEXAME: 'Administrativo;Invalidação de exame',
    ADMINISTRATIVO_PRIORIZACAOEXAME: 'Administrativo;Priorização de exame',
    /* -- Telemedicina -- */
    // /* -- Solicitação de exame -- */
    TELEMEDICINA_SOLICITACAOEXAME_ECG: 'Telemedicina;Solicitação de exame;ECG',
    TELEMEDICINA_SOLICITACAOEXAME_DERMATO: 'Telemedicina;Solicitação de exame;Dermatologia',
    TELEMEDICINA_SOLICITACAOEXAME_ELETROENCEFALO: 'Telemedicina;Solicitação de exame;EEG',
    TELEMEDICINA_SOLICITACAOEXAME_ESTOMATO: 'Telemedicina;Solicitação de exame;Estomatologia',
    TELEMEDICINA_SOLICITACAOEXAME_ESPIROMETRIA: 'Telemedicina;Solicitação de exame;Espirometria',
    TELEMEDICINA_SOLICITACAOEXAME_POLISSONO: 'Telemedicina;Solicitação de exame;Polissonografia',
    /* -- Envio de exame -- */
    TELEMEDICINA_ENVIOEXAME_ECG: 'Telemedicina;Envio de exame;ECG',
    TELEMEDICINA_ENVIOEXAME_DERMATO: 'Telemedicina;Envio de exame;Dermatologia',
    TELEMEDICINA_ENVIOEXAME_ELETROENCEFALO: 'Telemedicina;Envio de exame;EEG',
    TELEMEDICINA_ENVIOEXAME_ESTOMATO: 'Telemedicina;Envio de exame;Estomatologia',
    TELEMEDICINA_ENVIOEXAME_ESPIROMETRIA: 'Telemedicina;Envio de exame;Espirometria',
    TELEMEDICINA_ENVIOEXAME_POLISSONO: 'Telemedicina;Envio de exame;Polissonografia',
    /* -- Emissão de laudo -- */
    TELEMEDICINA_EMISSAOLAUDO_ECG: 'Telemedicina;Emissão de laudo;ECG',
    TELEMEDICINA_EMISSAOLAUDO_DERMATO: 'Telemedicina;Emissão de laudo;Dermatologia',
    TELEMEDICINA_EMISSAOLAUDO_ELETROENCEFALO: 'Telemedicina;Emissão de laudo;EEG',
    TELEMEDICINA_EMISSAOLAUDO_ESTOMATO: 'Telemedicina;Emissão de laudo;Estomatologia',
    TELEMEDICINA_EMISSAOLAUDO_ESPIROMETRIA: 'Telemedicina;Emissão de laudo;Espirometria',
    TELEMEDICINA_EMISSAOLAUDO_POLISSONO: 'Telemedicina;Emissão de laudo;Polissonografia',
    /* -- Telessaude -- */
    /* -- Solicitação -- */
    TELESSAUDE_TELECONSULTORIA_SOLICITACAO: 'Telessaúde;Teleconsultoria;Solicitação',
    /* -- Regulação -- */
    TELESSAUDE_TELECONSULTORIA_REGULACAO: 'Telessaúde;Teleconsultoria;Regulação',
    /* -- Resposta assíncrona -- */
    TELESSAUDE_TELECONSULTORIA_RESPOSTAASSINCRONA: 'Telessaúde;Teleconsultoria;Resposta Assíncrona',
    /* -- Resposta síncrona -- */
    TELESSAUDE_TELECONSULTORIA_RESPOSTASINCRONA: 'Telessaúde;Teleconsultoria;Resposta Síncrona',
    /* -- Avaliação -- */
    TELESSAUDE_TELECONSULTORIA_AVALIACAO: 'Telessaúde;Teleconsultoria;Avaliação',
    /* -- Solicitação de exame -- */
    SOLICITACAOEXAME_CANCERDEPELE: 'Solicitação de exame;Câncer de pele',
    SOLICITACAOEXAME_OUTRASDERMATOSES: 'Solicitação de exame;Outras dermatoses',
    SOLICITACAOEXAME_HANSENIASE: 'Solicitação de exame;Hanseníase',
    /* -- Realização de exame -- */
    REALIZACAOEXAME_CANCERDEPELE: 'Realização de exame;Câncer de pele',
    REALIZACAOEXAME_OUTRASDERMATOSES: 'Realização de exame;Outras dermatoses',
    REALIZACAOEXAME_HANSENIASE: 'Realização de exame;Hanseníase',
    /* Emissão de laudo > Dermatose */
    EMISSAOLAUDO_DERMATOSE_MELANOMACUTANEO: 'Emissão de laudo;Dermatose;Melanoma cutâneo',
    EMISSAOLAUDO_DERMATOSE_CECCBC: 'Emissão de laudo;Dermatose;CEC/CBC',
    EMISSAOLAUDO_DERMATOSE_HANSENIASE: 'Emissão de laudo;Dermatose;Hanseníase',
    EMISSAOLAUDO_DERMATOSE_PSORIASE: 'Emissão de laudo;Dermatose;Psoríase',
    /* Emissão de laudo > Classificação de risco */
    EMISSAOLAUDO_CLASSIFICACAORISCO_VERMELHA: 'Emissão de laudo;Classificação de risco;Vermelha',
    EMISSAOLAUDO_CLASSIFICACAORISCO_AMARELA: 'Emissão de laudo;Classificação de risco;Amarela',
    EMISSAOLAUDO_CLASSIFICACAORISCO_VERDE: 'Emissão de laudo;Classificação de risco;Verde',
    EMISSAOLAUDO_CLASSIFICACAORISCO_AZUL: 'Emissão de laudo;Classificação de risco;Azul',
    EMISSAOLAUDO_CLASSIFICACAORISCO_BRANCA: 'Emissão de laudo;Classificação de risco;Branca',
    /* Acesso a laudo > Dermatose */
    ACESSOLAUDO_DERMATOSE_MELANOMACUTANEO: 'Acesso a laudo;Dermatose;Melanoma cutâneo',
    ACESSOLAUDO_DERMATOSE_CECCBC: 'Acesso a laudo;Dermatose;CEC/CBC',
    ACESSOLAUDO_DERMATOSE_HANSENIASE: 'Acesso a laudo;Dermatose;Hanseníase',
    ACESSOLAUDO_DERMATOSE_PSORIASE: 'Acesso a laudo;Dermatose;Psoríase',
    /* Acesso a laudo > Classificação de risco */
    ACESSOLAUDO_CLASSIFICACAORISCO_VERMELHA: 'Acesso a laudo;Classificação de risco;Vermelha',
    ACESSOLAUDO_CLASSIFICACAORISCO_AMARELA: 'Acesso a laudo;Classificação de risco;Amarela',
    ACESSOLAUDO_CLASSIFICACAORISCO_VERDE: 'Acesso a laudo;Classificação de risco;Verde',
    ACESSOLAUDO_CLASSIFICACAORISCO_AZUL: 'Acesso a laudo;Classificação de risco;Azul',
    ACESSOLAUDO_CLASSIFICACAORISCO_BRANCA: 'Acesso a laudo;Classificação de risco;Branca'
  },
  /**
   * Inicializa variáveis necessárias
   * 
   * @param {object} config Objeto contendo os parâmetros de comunicação com o servidor
   */
  init: async (dbClient, config) => {
    ServicosMonitorados._url = config.url_base_monitoracao;
    ServicosMonitorados._token = config.token_monitoracao;
    ServicosMonitorados._timeout = config.timeout_monitoracao;
    ServicosMonitorados._dbClient = dbClient;
  },
  /**
   * Envia os dados para o serviço de monitoração
   * 
   * @param {object} dados 
   * @param {string} categoria 
   * @param {string} titulo 
   */
  enviar: async function (idInstituicao, categoria) {
    let titulo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : '';
    if (!idInstituicao) {
      throw new Error("Identificador da institui\xE7\xE3o deve ser informado");
    }
    if (!categoria) {
      throw new Error("Categoria deve ser informada");
    }
    if (!ServicosMonitorados._categorias.hasOwnProperty(categoria)) {
      throw new Error("Categoria \"".concat(categoria, "\" inv\xE1lida"));
    }
    const instituicao = await Instituicao.buscarDadosServicosMonitorados(ServicosMonitorados._dbClient, idInstituicao);
    if (!instituicao) {
      throw new Error("Institui\xE7\xE3o n\xE3o encontrada com identificador ".concat(idInstituicao));
    }
    const legendas = ServicosMonitorados._categorias[categoria];
    try {
      let dados = {
        cnes: instituicao.cnes,
        nome_instituicao: instituicao.nome,
        datahora_atual: new Date().getTime(),
        coordenadas_municipio: [instituicao.longitude_cidade, instituicao.latitude_cidade],
        nome_municipio: instituicao.nome_municipio,
        codigo_ibge: instituicao.codigo_ibge_cidade,
        categoria: categoria,
        legendas: legendas,
        titulo: titulo,
        token_acesso: ServicosMonitorados._token,
        uf: instituicao.sigla_uf
      };
      if (instituicao.latitude && instituicao.longitude) {
        dados.coordenadas_instituicao = [instituicao.longitude, instituicao.latitude];
      }
      const dadosJson = JSON.stringify(dados);
      const options = {
        headers: {
          'Content-Type': 'application/json'
        },
        payload: dadosJson,
        timeout: ServicosMonitorados._timeout
      };
      await Wreck.post(ServicosMonitorados._url, options);
    } catch (err) {
      console.log(err);
      throw err;
    }
  }
};
module.exports = ServicosMonitorados;