import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';
import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline } from '@material-ui/core';

import { ApolloClient, HttpLink, ApolloLink, ApolloProvider, InMemoryCache, concat } from '@apollo/client';

import Themes from './themes';
import App from './App';
import { SttTranslateHook } from '@stt-componentes/core';
import * as serviceWorker from './serviceWorker';
import { Cache } from '@stt-componentes/cache';

const initializeApp = (config) => {
    global.gConfig = config;

    /**
     * Configuração Graphql
     */
    const authMiddleware = new ApolloLink((operation, forward) => {
        // add the authorization to the headers
        operation.setContext({
            headers: {
                authorization: `Bearer ${Cache.getAccessToken()}`,
            },
        });

        return forward(operation);
    });
    const httpLink = new HttpLink({ uri: `${config.url_base_graphql}/graphql` });
    const client = new ApolloClient({
        link: concat(authMiddleware, httpLink),
        cache: new InMemoryCache({
            addTypename: false,
        }),
    });

    /**
     * Configuração Sentry
     */
    Sentry.init({
        dsn: 'https://4a2578a804714a29899e921a7c763699@sentry.telessaude.ufsc.br/4',
        release: process.env.REACT_APP_SENTRY_RELEASE,
        tracesSampleRate: 1.0,
        environment: config.id,
    });

    /**
     * Configuração React
     */
    ReactDOM.render(
        <ApolloProvider client={client}>
            <ThemeProvider theme={Themes.default}>
                <CssBaseline />
                <SttTranslateHook.I18nContextProvider modulo={'analise'}>
                    <App />
                </SttTranslateHook.I18nContextProvider>
            </ThemeProvider>
        </ApolloProvider>,
        document.getElementById('root')
    );
};

const verificarBaseAlternativa = (config) => {
    let subpaths = window.location.pathname;
    if (subpaths && config.layoutsAlternativos) {
        subpaths = subpaths.split('/');

        let layoutAlternativo = null;
        subpaths.forEach((sp) => {
            if (config.layoutsAlternativos.includes(sp)) {
                layoutAlternativo = sp;
                return true;
            }
        });

        if (layoutAlternativo) {
            return fetch(`${process.env.PUBLIC_URL}/config/layoutsAlternativos/${layoutAlternativo}/config.stt-${config.config_id}.json`, {
                headers: { 'Content-Type': 'application/json' },
            }).then((response) => response.json());
        }
    }
    return config;
};

Promise.all([
    fetch(`${process.env.PUBLIC_URL}/config/changelog.json`).then((response) => response.json()),
    fetch(`${process.env.PUBLIC_URL}/config/config.json`).then((response) => response.json()),
])
    .then(([changelog, config]) => Promise.all([changelog, verificarBaseAlternativa(config)]))
    .then(([changelog, config]) => initializeApp({ ...config, ...changelog }))
    .catch((error) => {
        console.error('Erro ao buscar os arquivos de configurações do frontend', error);
    });

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
