import React from 'react';
import { Route, withRouter, Redirect } from 'react-router-dom';
import { CacheSwitch } from 'react-router-cache-route';
import classnames from 'classnames';

// components
import Header from '../../components/Header/Header';

// styles
import useStyles from '../../themes/styles';
import SystemConstants from '../../util/SystemConstants';

import ErrorAlert from '../../components/Alerts/ErrorAlert';
import { NotificationContainer } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import MsgAlert from '../../components/Alerts/MsgAlert';
import { ErrorContent } from '../error/Error';

// Formulários globais
import NaoAutorizado from '../error/NaoAutorizado';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';
import { alertaSignal, setOpenAlert } from 'src/signals/alertaSignal';

const Interno = ({ history }) => {
    const classes = useStyles();
    const pages = [SystemConstants.HOME, ...usuarioSignal.value.submodulos];

    return (
        <div className={classes.root}>
            <>
                <Header history={history} />

                <CacheSwitch>
                    {pages.map((submodulo) => (
                        <Route
                            key={submodulo.id}
                            exact={submodulo.exact}
                            path={submodulo.path}
                            render={() => {
                                if (!usuarioSignal.value.isAuthorized) return <Redirect replace to="/apps/nao-autorizado" />;
                                return (
                                    <div
                                        className={submodulo.semPadding ? classnames(classes.mainContentSemPadding) : classnames(classes.mainContentComPadding)}
                                    >
                                        {React.createElement(SystemConstants.PAGES_MAP[submodulo.path])}
                                    </div>
                                );
                            }}
                        />
                    ))}
                    <Route key={100} exact path="/apps/nao-autorizado" component={NaoAutorizado} />
                    <Route component={ErrorContent} />
                </CacheSwitch>

                {/* Alertas Globais - @TODO Padronizar as duas formas de emitir alerta.*/}
                <ErrorAlert openAlert={alertaSignal.value.openAlert} errorCode={alertaSignal.value.errorCode} setOpenAlert={setOpenAlert} />
                <MsgAlert />
                <NotificationContainer />
            </>
        </div>
    );
};

export default withRouter(Interno);
