'use strict';

const moment = require('moment');
const getCorLegenda = linha => {
  const valido = linha.situacao === 'V';
  if (!valido) return '#ea9999';
  if (linha.situacao_laudo === 'T' || linha.situacao_laudo === 'D') return '#fce5cd';
  if (linha.situacao_laudo === 'N') {
    if (linha.data_priorizacao) return '#f4cbcb';
    const hoje = moment();
    const exameMais3Dias = moment(linha.data_exame).add(3, 'days');
    if (exameMais3Dias.isAfter(hoje)) return '#cee1f4';
    return '#faf7a9';
  }
  ;
  if (linha.situacao_laudo === 'S' || linha.situacao_laudo === 'M') return '#b0d8b1';
  if (linha.situacao_laudo === 'E') return '#d9ead2';
  if (linha.situacao_laudo === 'A') return '#fbd7b1';
  return '#cee1f4';
};
const getCorLegendaLaudoGrave = linha => {
  if (linha.situacao === 'Visualizado') return '#b0d8b1';
  if (linha.situacao === 'Verificado') return '#faf7a9';
  return '#ea9999';
};
const getTextoLegenda = (linha, strings) => {
  const valido = linha.situacao === 'V';
  if (!valido) return strings.invalido;
  if (linha.situacao_laudo === 'T' || linha.situacao_laudo === 'D') return strings.laudoTemporario;
  if (linha.situacao_laudo === 'N') {
    if (linha.data_priorizacao) return strings.priorizado;
    const hoje = moment();
    const exameMais3Dias = moment(linha.data_exame).add(3, 'days');
    if (exameMais3Dias.isAfter(hoje)) return strings.semLaudo;
    return strings.semLaudo72h;
  }
  ;
  if (linha.situacao_laudo === 'S' || linha.situacao_laudo === 'M') return strings.comLaudo;
  if (linha.situacao_laudo === 'E') return strings.laudoEmissao;
  if (linha.situacao_laudo === 'A') return strings.laudoAguardandoComplemento;
  return strings.semLaudo;
};
module.exports = {
  getCorLegenda,
  getTextoLegenda,
  getCorLegendaLaudoGrave
};