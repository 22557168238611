module.exports = {
  LoginForm: {
    entrarNoSistema: 'Entrar no sistema',
    entrarMicrosoftAd: 'Entrar com Microsoft AD',
    esqueceuSenha: 'Esqueceu sua senha?',
    usuarioObrigatorio: 'Usuário é obrigatório',
    senhaObrigatoria: 'Senha é obrigatória',
    acessoSistema: 'Acesso ao sistema',
    novoCadastro: 'Cadastre-se aqui',
    mensagemNecessidadeAutenticacaoAzure: 'Você deverá vincular seu e-mail da EBSERH (@ebserh.gov.br) para continuar com o login.'
  },
  TermoUsoForm: {
    tituloModal: 'Termo de Uso e a Política de Privacidade',
    liConcordo: 'Li e concordo.',
    textoModal: "<p>Prezado(a) usu\xE1rio(a),</p>\n        <p>O Termo de Uso e a Pol\xEDtica de Privacidade do Telessa\xFAde UFSC <a href=\"https://telessaude.ufsc.br/privacidade/\" target=\"_blank\">(https://telessaude.ufsc.br/privacidade/)</a> est\xE3o dispon\xEDveis para a sua consulta e concord\xE2ncia. Estes documentos est\xE3o de acordo com a Lei Geral de Prote\xE7\xE3o de Dados (LGPD) e \xE9 necess\xE1rio o seu consentimento para o uso dos servi\xE7os do Telessa\xFAde UFSC.</p>"
  },
  WhatsAppForm: {
    tituloModal: 'Grupo de WhatsApp: Telessaúde UFSC - Divulgações',
    textoModal: "<p style=\"font-size:large;\">Agora, voc\xEA pode receber as divulga\xE7\xF5es e informes sobre os servi\xE7os do Telessa\xFAde UFSC atrav\xE9s do nosso grupo no WhatsApp. Esperamos por voc\xEA, participe!</p>\n        <p style=\"font-size:large;\">Aponte a c\xE2mera do seu celular para o c\xF3digo QR da imagem ou acesse atrav\xE9s do link: <a href=\"https://bit.ly/whatsapp-telessaudeufsc2\" target=\"_blank\">(https://bit.ly/whatsapp-telessaudeufsc2)</a></p>",
    imagemModal: '<a href="https://bit.ly/whatsapp-telessaudeufsc2" target="_blank"><img style="width: 100%; object-fit: contain;" src="./qrcode-grupo-telessaude.png" alt="Imagem com QRcode para acessar o grupo de WhatsApp da Telessaúde."></a>'
  },
  EsqueceuSenha: {
    continuar: 'Continuar',
    emailEnviado: 'E-mail enviado',
    fazerCadastro: 'Fazer cadastro',
    mensagemConfirmacao: 'O link para recuperação de senha será enviado para o seguinte e-mail cadastrado no STT: %s. Certifique-se de utilizar o link em até 1 hora. Quer trocar seu e-mail de cadastro? Entre em contato com %s.',
    mensagemSemCadastro: 'O CPF %s não foi localizado no cadastro do STT.',
    mensagemSucesso: 'As instruções para redefinição da sua senha foram enviadas para o e-mail %s. Verifique sua caixa de entrada ou SPAM e siga as instruções para criar sua nova senha.',
    recuperarSenha: 'Recuperar senha'
  },
  TokenInvalido: {
    tokenInvalido: 'Token inválido'
  },
  Cabecalho: {
    modulos: 'Módulos',
    meusDados: 'Meus dados',
    alteracaoSenha: 'Alteração de senha',
    alteracaoFoto: 'Alteração de foto'
  },
  MeusDados: {
    meusDados: 'Meus dados',
    dadosPessoais: 'Dados pessoais',
    conexoes: 'Conexões',
    semConexoes: 'Ainda não há conexões disponíveis para autenticação.'
  },
  MeusDadosForm: {
    acessoWeasis: 'Acesso ao visualizador DICOM Weasis',
    alterarSenha: 'Alterar senha',
    avisoLaudoTranscricao: 'As funcionalidades "Transcrição de voz" e "Fórmulas nos modelos de laudo" estão disponíveis apenas no STT versão 1.',
    avisoWeasis: 'O Weasis deve ser baixado e instalado de acordo com seu sistema operacional (versão mínima: 3.6.0). Para fazer o download,',
    confirmarAlteracao: 'Você confirma a alteração dos seus dados?',
    divulgacaoEmail: 'Aceito receber divulgação por email.',
    downloadWeasis: 'Download do Weasis para cada exame',
    formulasLaudo: 'Fórmulas nos modelos de laudo',
    galeria: 'Galeria',
    infoDivulgacao: "As divulga\xE7\xF5es enviadas semanalmente informam sobre a agenda de atividades de Tele-educa\xE7\xE3o e as novidades dos servi\xE7os de Telediagn\xF3stico e Teleconsultoria.\n                            Obs.: O Telessa\xFAde UFSC reserva-se ao direito de enviar comunicados importantes sobre o uso e a oferta dos servi\xE7os sem autoriza\xE7\xE3o de seus usu\xE1rios.",
    instaladoWeasis: 'Weasis instalado no computador',
    ohif: 'OHIF (visualizador DICOM web)',
    oviyam: 'Oviyam (visualizador DICOM web)',
    transcricaoVoz: 'Transcrição de voz (apenas para o navegador Google Chrome)',
    visualizadorImagens: 'Visualização de imagens'
  },
  MeusDadosFormFoto: {
    alteracaoFoto: 'Alteração de foto',
    fotoUsuario: 'Foto do usuário',
    confirmarFoto: 'Você confirma a alteração da foto?',
    formatosArquivo: 'Arquivo (.jpg, .jpeg)',
    fotoGrande: 'Você escolheu uma foto de resolução muito grande. Por favor, selecione outra imagem com resolução inferior a 2000px de largura por 2000px de altura.',
    instrucao: "Nessa se\xE7\xE3o voc\xEA pode inserir uma imagem contendo sua foto. Essa imagem dever\xE1 ser no formato JPG, JPEG e com dimens\xF5es m\xEDnimas de 200x200px e m\xE1ximas de 2000x2000px, ou respeitando a raz\xE3o 1:1 como exibido na figura abaixo."
  },
  MeusDadosFormSenha: {
    confirmarAlteracao: 'Você confirma a alteração da sua senha?',
    senhaAtual: 'Senha atual',
    alteracaoSenha: 'Alteração de senha',
    confirmacaoSenha: 'Confirmação de senha',
    novaSenha: 'Nova senha',
    novaSenhaConfirmacaoCpf: 'A senha não pode conter o CPF',
    novaSenhaConfirmacaoDataNascimento: 'A senha não pode conter sua data de nascimento',
    novaSenhaConfirmacaoEmail: 'A senha não pode conter o Email',
    novaSenhaConfirmacaoLogin: 'A senha não pode conter o Login de usuário',
    novaSenhaConfirmacaoNome: 'A senha não pode conter o nome',
    novaSenhaConfirmacaoSenhaIgual: 'A nova senha e a confirmação de senha devem ser iguais',
    senhaAoMenosUmaMaiuscula: 'A senha deve conter pelo menos uma letra maiúscula.',
    senhaAoMenosUmaMinuscula: 'A senha deve conter pelo menos uma letra minúscula.',
    senhaAoMenosUmCaracter: 'A senha deve conter pelo menos um caractere especial.',
    senhaAoMenosUmNumero: 'A senha deve conter pelo menos um número.',
    senhaMinimo9Caracteres: 'A senha deve ter no mínimo 8 caracteres.',
    senhaSemEspacos: 'A senha não pode conter espaços.',
    chackList8Caracteres: 'Pelo menos 8 caracteres.',
    chackListAlfabetico: 'Caractere alfabético.',
    chackListNumerico: 'Caractere numérico.',
    chackListEspecial: 'Caractere especial.',
    chackListMinuscula: 'Letra minúscula.',
    chackListMaiuscula: 'Letra maiúscula.'
  },
  ModuloAcesso: {
    modulos: 'Módulos',
    alertaAusenciaModulos: 'Não há módulos disponíveis para acesso ao seu usuário. Por favor, contate um administrador.'
  },
  FormNovaSenha: {
    senhaObrigatoria: 'Senha é obrigatória',
    redefinirSenha: 'Redefinir senha',
    novaSenha: 'Nova senha',
    repitaNovaSenha: 'Repita nova senha'
  }
};