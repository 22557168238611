import moment from 'moment';
import _ from 'lodash';
import { normalizeValorEmail } from '../common/FiltroUtil';
import 'moment/locale/pt-br';
moment.locale('pt-br');
/**
 * Intervalos permitidos para agendamento de um job que possui um filtro para escolher.
 */
export var FiltroIntervalo;
(function (FiltroIntervalo) {
  FiltroIntervalo["MES_ANTERIOR"] = "M\u00EAs anterior";
  FiltroIntervalo["SEMANA_ANTERIOR"] = "Semana anterior";
  FiltroIntervalo["ULTIMOS_7DIAS"] = "\u00DAltimos 7 dias";
  FiltroIntervalo["ULTIMOS_30DIAS"] = "\u00DAltimos 30 dias";
})(FiltroIntervalo || (FiltroIntervalo = {}));
/**
 * Criar um filtro para relatórios padronizados.
 *
 * @param filtroIntervaloValor
 * @param filtroIntervaloVariavel
 */
export const montarFiltroSQLPadronizado = filtroIntervaloValor => {
  let end, start;
  switch (filtroIntervaloValor) {
    case FiltroIntervalo.MES_ANTERIOR:
      const refMonth = moment().subtract(1, 'months');
      end = refMonth.endOf('month').toDate();
      start = refMonth.startOf('month').toDate();
      break;
    case FiltroIntervalo.SEMANA_ANTERIOR:
      const refWeek = moment().subtract(1, 'week');
      end = refWeek.endOf('week').toDate();
      start = refWeek.startOf('week').toDate();
      break;
    case FiltroIntervalo.ULTIMOS_7DIAS:
      end = moment().toDate();
      start = moment().subtract(7, 'days').toDate();
      break;
    case FiltroIntervalo.ULTIMOS_30DIAS:
      end = moment().toDate();
      start = moment().subtract(30, 'days').toDate();
      break;
  }
  const format = 'YYYY-MM-DD HH:mm:ss';
  const startFormatted = moment(start).utcOffset('-03:00').format(format);
  const endFormatted = moment(end).utcOffset('-03:00').format(format);
  return [startFormatted, endFormatted];
};
/**
 * Criar um filtro a partir das opções selecionadas pelo usuário.
 *
 * @param filtroIntervaloValor
 * @param filtroIntervaloVariavel
 */
export const montarFiltroSecundario = (filtroIntervaloValor, filtroIntervaloVariavel) => {
  console.log(filtroIntervaloValor);
  let end, start;
  switch (filtroIntervaloValor) {
    case FiltroIntervalo.MES_ANTERIOR:
      const refMonth = moment().subtract(1, 'months');
      end = refMonth.endOf('month').toDate();
      start = refMonth.startOf('month').toDate();
      break;
    case FiltroIntervalo.SEMANA_ANTERIOR:
      const refWeek = moment().subtract(1, 'week');
      end = refWeek.endOf('week').toDate();
      start = refWeek.startOf('week').toDate();
      break;
    case FiltroIntervalo.ULTIMOS_7DIAS:
      end = moment().toDate();
      start = moment().subtract(7, 'days').toDate();
      break;
    case FiltroIntervalo.ULTIMOS_30DIAS:
      end = moment().toDate();
      start = moment().subtract(30, 'days').toDate();
      break;
  }
  console.log([start, end]);
  return {
    atributo: filtroIntervaloVariavel,
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => [start, end]
  };
};
/**
 * Montar nome do arquivo único e sem caracteres especiais.
 */
export const montarNomeArquivo = function (titulo) {
  let tipo = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 'xlsx';
  const newTitulo = _.kebabCase(titulo);
  return "".concat(newTitulo).concat(new Date().getTime(), ".").concat(tipo);
};
export const montarFiltrosEmail = (nFiltros, metaAtributos) => {
  return nFiltros.map(_ref => {
    let {
      atributo,
      valor,
      tipoComponente
    } = _ref;
    // [1] Recuperar o nome da variável
    const meta = metaAtributos.find(e => e.atributo === atributo);
    const eVariavel = (meta === null || meta === void 0 ? void 0 : meta.rotulo) || atributo;
    // [2] Montar o valor de acordo com sua operação.
    const nValor = normalizeValorEmail({
      valor,
      tipoComponente
    });
    return {
      eVariavel,
      eValor: nValor
    };
  });
};
export const disassembleRepeat = _ref2 => {
  let {
    day,
    hourFormat,
    dayOfWeek
  } = _ref2;
  let alg = ['6', '0', '1', '*', '*']; // default
  if (day) {
    alg[2] = day;
  } else if (dayOfWeek) {
    alg[4] = dayOfWeek;
  }
  if (hourFormat) {
    const [hour, minute] = hourFormat.split(':');
    alg[0] = minute;
    alg[1] = hour;
  }
  return alg.join(' ');
};
const assemble = function () {
  let cron = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : '00 6 1 * *';
  console.log(cron);
  const alg = cron.split(' ');
  if (alg.length !== 5) {
    throw new Error('Problema na cron');
  }
  const hour = alg[1] === '*' ? 6 : Number(alg[1]);
  const minute = alg[0] === '*' ? 0 : Number(alg[0]);
  const time = moment({
    hour,
    minute,
    second: 0
  });
  const hourFormat = time.format('HH:mm');
  // Regra para escolher um ou outro
  let day, dayOfWeek;
  dayOfWeek = alg[4] === '*' ? null : alg[4];
  if (!dayOfWeek) {
    day = alg[2] === '*' ? '1' : alg[2];
  }
  return [day, hourFormat, dayOfWeek];
};
export const assembleRepeat = cron => {
  const [day, hour, dayOfWeek] = assemble(cron);
  const tipoCron = dayOfWeek ? 'Semanal' : 'Mensal';
  return [day, hour, dayOfWeek, tipoCron];
};
export const assembleRepeatText = cron => {
  const [day, hour, dayOfWeek, tipoCron] = assembleRepeat(cron);
  const daysOfWeeks = ['Domingo', 'Segunda-feira', 'Terça-feira', 'Quarta-feira', 'Quinta-feira', 'Sexta-feira', 'Sábado', 'Domingo'];
  switch (tipoCron) {
    case 'Semanal':
      return "Toda ".concat(daysOfWeeks[Number(dayOfWeek)], " de cada semana, \xE0s ").concat(hour);
    case 'Mensal':
      return "Todo dia ".concat(day, " de cada m\xEAs, \xE0s ").concat(hour);
    default:
      return cron;
  }
};
export const validarCron = cron => {
  const [day, hour, dayOfWeek] = assemble(cron);
  if (dayOfWeek && hour) return true;
  if (day && hour) return true;
  return false;
};