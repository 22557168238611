import { signal } from '@preact/signals-react';

// const alerta = signal({
//     open: false,
//     title: '',
//     type: 'alert',
//     message: '',
//     options: [],
//     onClose: null,
// });

export const alertaSignal = signal({
    openAlert: false,
    errorCode: 'ERR001',
    msgAlert: null,
    openMsgAlert: false,
    yesCallback: () => {},
    noCallback: () => {},
});

export const setOpenAlert = (openAlert, errorCode) => {
    alertaSignal.value = { ...alertaSignal.value, openAlert, errorCode };
};

export const setOpenMsgAlert = (openMsgAlert) => {
    alertaSignal.value = { ...alertaSignal.value, openMsgAlert };
};

export const triggerAlert = (msgAlert, yesCallback = () => {}, noCallback = () => {}) => {
    alertaSignal.value = { ...alertaSignal.value, msgAlert, yesCallback, noCallback, openMsgAlert: true };
};
