const stringsComuns = require('./comum');
const estomatoComum = {
  erroGerarTermo: 'Não foi possível gerar o termo de autorização. Tente imprimir o termo através da tela de exames.',
  exposicaoSol: 'Exposição dos lábios ao sol',
  exposicaoSolHorasDia: 'Exposição ao sol (h/dia)',
  exposicaoSolMesesAno: 'Exposição ao sol (meses/ano)',
  habitos: 'Hábitos',
  lesao: 'Lesão',
  lesoes: 'Lesões',
  usoProtecaoSolar: 'Uso de proteção solar nos lábios'
};
module.exports = {
  abrangenciaProtese: 'Abrangência da prótese',
  alteracaoSensibilidade: 'Alteração da sensibilidade',
  alterarEstruturaAfetada: 'Alterar estrutura afetada',
  alterarMeusDados: stringsComuns.alterarMeusDados,
  altura: 'Altura',
  ambas: 'Ambas (superior e inferior)',
  anosFumou: 'Anos que fumou',
  anosUso: 'Anos de uso',
  antiga: 'Antiga',
  arcadaAfetada: 'Arcada afetada',
  assinando: 'Assinando laudo digitalmente',
  assintomatica: 'Assintomática',
  assintomatico: 'Assintomático',
  aumentoSensibilidade: 'Aumento da sensibilidade',
  bilateral: 'Bilateral (direito E esquerdo)',
  carregandoExame: 'Carregando exame',
  cirurgiaoDentista: 'Cirurgião dentista',
  classificacaoRisco: 'Classificação de risco',
  confirmando: 'Registrando laudo',
  consumoBebidasAlcoolicas: 'Consumo de bebidas alcoólicas',
  copiarLaudo: 'Copiar laudo',
  copiarLaudoInfo: 'Os dados preechidos em "Lesão fundamental", "Localização", "Cor", "Superfície", "Classificação de risco" e "Hipótese diagnóstica" serão copiados para a lesão informada.',
  copiarPara: 'Copiar para:',
  cor: 'Cor',
  dadosCopiados: 'Dados copiados',
  dataAquisicao: 'Data de aquisição',
  dor: 'Dor',
  duasQuatroVezezMes: '2-4 vezes ao mês',
  duasTresVezesSemana: '2-3 vezes por semana',
  duracaoDor: 'Duração da dor',
  envioImagens: stringsComuns.envioImagens,
  espontaneidadeDor: 'Espontaneidade da dor',
  estruturaAfetada: 'Estrutura afetada',
  etilista: 'Consumo de bebida alcoólica',
  exameInvalido: 'Exame inválido',
  exames: stringsComuns.exames,
  exFumante: 'Ex-fumante',
  exposicaoSol: estomatoComum.exposicaoSol,
  exposicaoSolHorasDia: estomatoComum.exposicaoSolHorasDia,
  exposicaoSolMesesAno: estomatoComum.exposicaoSolMesesAno,
  exUsuario: 'Ex-usuário',
  frequenciaUsoProtecaoSolar: 'Frequência de uso de proteção solar nos lábios',
  fumante: 'Fumante',
  habitos: estomatoComum.habitos,
  hipoteseDiagnostica: 'Hipótese diagnóstica',
  inferior: 'Inferior',
  inicio: stringsComuns.inicio,
  intensidadeDor: 'Intensidade da dor',
  ladoAfetado: 'Lado afetado',
  largura: 'Largura',
  laudo: stringsComuns.laudo,
  laudosGraves: estomatoComum.laudosGraves,
  laudoTextual: 'Laudo textual',
  lesaiSaiRaspagem: 'Lesão sai à raspagem?',
  lesao: 'Lesão',
  lesaoFundamental: 'Lesão fundamental',
  lesaoSaiRaspagem: 'Sai à raspagem',
  lesaoUnica: 'Lesão única',
  lesoes: 'Lesões',
  lesoesMultiplas: 'Lesões múltiplas',
  linhaMedia: 'Linha média',
  localizacaoDor: 'Localização da dor',
  localizacaoLesao: 'Localização',
  materiaisSuporte: stringsComuns.materiaisSuporte,
  medicoExecutor: 'Médico executor',
  mensalmente: 'Mensalmente',
  modulo: 'Estomatologia',
  mordedura: 'Mordedura',
  msgAlertaSemLesao: 'Por favor, não esquecer de preencher no campo da queixa principal o detalhamento do caso.',
  naoConhecido: 'Não conhecido',
  naoSeAplica: 'Não se aplica',
  naoUsuario: 'Não usuário',
  nenhumaLesaoAdicionada: 'Nenhuma lesão adicionada',
  nenhumaLesaoInformada: 'Nenhuma lesão informada',
  nenhummExameParaLaudo: 'Nenhum exame disponível para laudo',
  nomeSistema: stringsComuns.nomeSistema,
  notasAtualizacao: stringsComuns.notasAtualizacao,
  nova: 'Nova',
  observacaoSuperficie: 'Observação relacionada à superfície da lesão',
  observacoesAdicionaisLesao: 'Observações adicionais da região da lesão',
  ocorrencia: 'Ocorrência',
  ocorrenciaLesao: 'Ocorrência da lesão',
  orientacoes: 'Orientações',
  outra: 'Outra',
  outraCor: 'Outra cor',
  outrasDrogas: 'Outras drogas',
  pacienteAssintomatico: 'Paciente assintomático',
  pacienteRealizouTratamentoPrevio: 'O paciente realizou algum tratamento prévio?',
  pacienteUsuarioProtese: 'Paciente usuário de prótese',
  palpacao: 'Palpação',
  parcial: 'Parcial',
  pendencias: stringsComuns.pendencias,
  perdaSensibilidade: 'Perda de sensibilidade',
  possibilidadeTraumaLocal: 'Possibilidade de trauma local',
  proteseMalAdaptada: 'Prótese mal adaptada',
  proximoExame: 'Próximo exame',
  quatroOuMaisSemana: '4 ou mais vezes por semana',
  queimacao: 'Queimação',
  queixaPrincipal: 'Queixa principal',
  regiaoAfetada: 'Região afetada',
  responsavelAquisicao: 'Responsável pela aquisição',
  sair: stringsComuns.sair,
  semLesao: 'Sem lesão',
  semLesaoIdentificavel: 'Sem lesão identificável',
  semTratamento: 'Sem tratamento',
  semTrauma: 'Sem trauma',
  sintomatologia: 'Sintomatologia',
  situacaoProtese: 'Situação da prótese',
  solicitacao: stringsComuns.solicitacao,
  superficie: 'Superfície',
  superior: 'Superior',
  surtiuEfeito: 'Surtiu efeito?',
  tamanho: 'Tamanho',
  tempoEtilismo: 'Consome bebida alcoólica há quanto tempo?',
  tempoEvolucao: 'Tempo de evolução',
  tempoExEtilista: 'Parou de beber há quanto tempo?',
  tempoExTabagista: 'Parou de fumar há quanto tempo?',
  tempoExUsuarioDrogas: 'Parou de usar outras drogas há quanto tempo?',
  tempoTabagismo: 'Fuma há quanto tempo?',
  tempoUsoProtecaoSolar: 'Tempo de uso de proteção solar nos lábios',
  tempoUsuarioDrogas: 'Usa outras drogas há quanto tempo?',
  tipoBebida: 'Tipo de bebida',
  tipoCrescimento: 'Tipo de crescimento',
  tipoDor: 'Tipo de dor',
  tipoDroga: 'Tipo de droga',
  tipoFumo: 'Tipo de fumo',
  tipoTratamento: 'Tipo de tratamento',
  total: 'Total',
  tratamentoPrevio: 'Tratamentos prévios (se houver)',
  tratamentoPrevio: 'Tratamento prévio',
  unidadeMedida: 'Unidade de medida',
  unilateral: 'Unilateral (direito OU esquerdo)',
  usoProtecaoSolar: estomatoComum.usoProtecaoSolar,
  usuario: 'Usuário',
  usuarioDrogas: 'Uso de outras drogas'
};