import _ from 'lodash';
import chroma from 'chroma-js';
import { SPLIT_CHAR_SPECIAL } from '../constants/Split';
const mountDataset = obj => {
  const data = [];
  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const count = obj[key];
      data.push({
        label: key,
        count: count
      });
    }
  }
  return data;
};
const countByNDimension = function (dataset) {
  let applyAverage = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;
  const dimension = dataset[0].length;
  if (dimension === 2) {
    return dataset.reduce((acc, curr) => {
      acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] = acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] ? acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] + 1 : 1;
      return acc;
    }, {});
    // return _.countBy(dataset);
  } else if (dimension > 2) {
    let weight = {};
    let countByKey = dataset.reduce((acc, curr) => {
      acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] = acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] ? acc["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] + curr[2] : curr[2];
      if (applyAverage) weight["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] = weight["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] ? weight["".concat(curr[0]).concat(SPLIT_CHAR_SPECIAL).concat(curr[1])] + 1 : 1;
      return acc;
    }, {});
    if (applyAverage) {
      for (const key in countByKey) {
        if (Object.hasOwnProperty.call(countByKey, key)) {
          countByKey[key] = countByKey[key] / weight[key];
        }
      }
    }
    return countByKey;
  } else {
    throw new Error('Dataset não suportado.');
  }
};
const toBarChart = function (datasetDruid) {
  let {
    applySorting = true
  } = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  /**
   * Deletar o cabeçalho
   */
  datasetDruid.shift();
  /**
   * Agrupamento pelas duas dimensões.
   * Montar uma estrutura (chave, valor) em árvore.
   */
  if (applySorting) {
    datasetDruid = _.sortBy(datasetDruid, []);
  }
  const result = {};
  const labels = new Set();
  const countBy = countByNDimension(datasetDruid);
  let countColors = 0;
  for (const key in countBy) {
    if (Object.hasOwnProperty.call(countBy, key)) {
      const count = countBy[key];
      const [firstDim, secondDim] = key.split(SPLIT_CHAR_SPECIAL);
      if (!result[firstDim]) {
        result[firstDim] = {};
        countColors++;
      }
      if (!result[firstDim][secondDim]) {
        result[firstDim][secondDim] = count;
      } else {
        result[firstDim][secondDim] += count;
      }
      labels.add(secondDim);
    }
  }
  /**
   * Contabilizar número de cores
   */
  const colors = chroma.scale(['yellow', 'red', 'teal']).colors(countColors);
  let indexColor = 0;
  /**
   * Separar em vários dataset por uma dimensão
   */
  const datasets = [];
  for (const key in result) {
    if (Object.hasOwnProperty.call(result, key)) {
      const innerData = result[key];
      datasets.push({
        axis: 'y',
        backgroundColor: colors[indexColor++],
        label: key,
        data: mountDataset(innerData)
      });
    }
  }
  return [Array.from(labels), datasets];
};
export default {
  toBarChart
};