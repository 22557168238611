import React, { useState } from 'react';

// Componentes UI
import { Grid, Typography, Paper } from '@material-ui/core';

// Componentes STT
import { Cache } from '@stt-componentes/cache';
import { SttCacheManager } from '@stt-componentes/core';
import { SttCircularProgress } from '@stt-componentes/core';

// Styles
import useStyles from './styles';

/* @useSignals */
import { alterarUsuario } from '../../signals/usuario';

const Authenticate = ({ onLoaded }) => {
    const classes = useStyles();

    const [loaded, setLoaded] = useState(false);

    return (
        <>
            {!loaded && (
                <Grid container className={classes.container}>
                    <Paper classes={{ root: classes.paperRoot }}>
                        <SttCircularProgress color="primary" />
                        <Typography variant="h2" color="primary" className={classes.textRow}>
                            Carregando...
                        </Typography>
                    </Paper>
                </Grid>
            )}
            <SttCacheManager
                config={global.gConfig}
                onSync={(loaded) => {
                    setLoaded(loaded);
                    const userInfo = Cache.getUserInfo();
                    alterarUsuario(userInfo);
                    onLoaded(loaded);
                }}
            />
        </>
    );
};

export default Authenticate;
