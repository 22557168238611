const moment = require('moment');
const FiltroConstants = require('../../constants/FiltroConstants').default;
module.exports = {
  cubo: 'cubo_dashboard_teleconsultoria',
  primeiraConsultaAutomatica: false,
  atributosSelecionados: ['__time', 'id', 'teleconsultoria_finalizada', 'teleconsultoria_avaliada', 'data_solicitacao', 'data_atendimento', 'especificacao', 'rede_teleconsultoria', 'rede_teleconsultoria_abrev', 'nome_solicitante', 'cbo_familia_solicitante', 'cbo_ocupacao_solicitante', 'orientacao_especifica', 'orientacao_especifica_sigla', 'nome_teleconsultor', 'cbo_familia_teleconsultor', 'cbo_ocupacao_teleconsultor', 'nome_instituicao', 'satisfacao_geral', 'tempo_resposta_hora', 'cid10', 'ciap2', 'nome_paciente', 'cns_paciente', 'cpf_paciente', 'lida_solicitante', 'medida_contador' // Sempre colocar o último atributo como métrica.
  ],
  charts: [{
    title: 'teleconsultorias realizadas',
    description: 'Teleconsultorias respondidas.',
    type: 'CardAbsoluteChart',
    selectDimensions: ['medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    transformations: []
  }, {
    title: 'de teleconsultorias com recomendação de manejo na APS',
    description: 'Teleconsultorias respondidas.',
    type: 'CardPercentageChart',
    selectDimensions: ['orientacao_especifica_sigla', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    having: ['M'],
    transformations: []
  }, {
    title: 'encaminhamentos desnecessários potencialmente evitados',
    description: 'Teleconsultorias respondidas.',
    type: 'CardAbsoluteChart',
    w: 2,
    selectDimensions: ['medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }, {
      attribute: 'orientacao_especifica_sigla',
      filter: value => value == 'M'
    }],
    transformations: []
  }, {
    title: 'de teleconsultorias respondidas em até 72h',
    description: 'Teleconsultorias respondidas.',
    type: 'CardPercentage2Chart',
    selectDimensions: ['tempo_resposta_hora', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    having: 'Sim',
    transformations: [{
      attributes: ['tempo_resposta_hora'],
      transformation: value => value !== null && value <= 72 ? 'Sim' : 'Não'
    }]
  }, {
    title: 'teleconsultorias avaliadas como "boa/muito boa"',
    description: 'Teleconsultorias respondidas e avaliadas.',
    type: 'CardPercentage2Chart',
    selectDimensions: ['satisfacao_geral', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_avaliada',
      filter: value => value == 'Sim'
    }],
    having: ['Muito satisfeito(a)', 'Satisfeito(a)'],
    transformations: []
  }, {
    title: '1. ORIENTAÇÕES DAS TELECONSULTORIAS SEGUNDO A REDE DE TELECONSULTORIA',
    description: 'Teleconsultorias respondidas.',
    type: 'ApexBarChart',
    selectDimensions: ['orientacao_especifica', 'rede_teleconsultoria_abrev', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    transformations: [],
    uiConfig: {
      enableAnimation: true
    }
  }, {
    title: '2. AVALIAÇÕES DAS TELECONSULTORIAS SEGUNDO A REDE DE TELECONSULTORIA',
    description: 'Teleconsultorias avaliadas.',
    type: 'ApexBarChart',
    selectDimensions: ['satisfacao_geral', 'rede_teleconsultoria_abrev', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_avaliada',
      filter: value => value == 'Sim'
    }],
    transformations: [],
    uiConfig: {
      chartLegendColors: {
        'Não preenchido': '#fdef19',
        Indiferente: '#d0d0ce',
        'Muito insatisfeito(a)': '#000000',
        'Insatisfeito(a)': '#cc0000',
        'Satisfeito(a)': '#00b23d',
        'Muito satisfeito(a)': '#2e93fa'
      },
      chartLegendOrder: ['Indiferente', 'Não preenchido', 'Muito insatisfeito(a)', 'Insatisfeito(a)', 'Satisfeito(a)', 'Muito satisfeito(a)']
    }
  }, {
    title: '3. RESPOSTAS DAS TELECONSULTORIAS DENTRO DAS 72H SEGUNDO A REDE DE TELECONSULTORIA',
    description: 'Teleconsultorias respondidas.',
    type: 'ApexBarChart',
    selectDimensions: ['tempo_resposta_hora', 'rede_teleconsultoria_abrev', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    transformations: [{
      attributes: ['tempo_resposta_hora'],
      transformation: value => value !== null && value <= 72 ? 'Sim' : 'Não'
    }]
  }, {
    type: 'Inner',
    inner: {
      cubo: 'cubo_dashboard_teleconsultoria_atrasadas',
      excludeFiltros: [1],
      atributosSelecionados: ['prazo', 'rede_teleconsultoria', 'medida_contador'],
      charts: [{
        title: '4. TELECONSULTORIAS EM ABERTO NA DATA E HORA DO ÚLTIMO PROCESSAMENTO',
        description: 'Solicitações de teleconsultorias.',
        type: 'ApexBarChart',
        selectDimensions: ['prazo', 'rede_teleconsultoria', 'medida_contador'],
        filters: [],
        transformations: [{
          attributes: ['prazo'],
          transformation: value => {
            switch (value) {
              case 'Atrasados 7+ dias':
                return 'Em aberto há 7 dias ou mais';
              case 'Atrasadas há menos de 7 dias':
                return 'Em aberto há menos de 7 dias';
              case 'Dentro do prazo':
                return 'Dentro do prazo de 72 horas';
              default:
                return value;
            }
          }
        }, {
          attributes: ['rede_teleconsultoria'],
          transformation: value => {
            const values = value === null || value === void 0 ? void 0 : value.split('->');
            if (values.length > 1) return values[1];
            return value;
          }
        }],
        uiConfig: {
          chartLegendColors: {
            'Não preenchido': '#d0d0ce',
            'Em aberto há 7 dias ou mais': '#cc0000',
            'Em aberto há menos de 7 dias': '#fdef19',
            'Dentro do prazo de 72 horas': '#2e93fa'
          }
        }
      }]
    }
  }, {
    title: '5. ORIENTAÇÕES DAS TELECONSULTORIAS SEGUNDO TELECONSULTOR',
    description: 'Teleconsultorias respondidas. São exibidos apenas os 20 teleconsultores com o maior número de orientações. Para visualizar o resultado completo, faça o download dos dados em formato de planilha.',
    type: 'ApexBarChart',
    selectDimensions: ['orientacao_especifica', 'nome_teleconsultor', 'medida_contador'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    transformations: [],
    uiConfig: {
      limitBarChart: 20
    }
  }, {
    title: '6. TELECONSULTORIAS RESPONDIDAS POR TELECONSULTOR',
    description: 'Teleconsultorias respondidas. São exibidos apenas os 20 teleconsultores com o maior número de respostas. Para visualizar o resultado completo, faça o download dos dados em formato de planilha.',
    type: 'TableChart',
    limitTable: 20,
    selectDimensions: ['nome_teleconsultor', 'rede_teleconsultoria_abrev', 'medida_contador'],
    labels: ['TELECONSULTOR', 'TELECONSULTORIAS RESPONDIDAS', 'REDE DE TELECONSULTORIA'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value === 'Sim'
    }],
    transformations: []
  }, {
    title: '7. PLANILHA COM DADOS ADICIONAIS SOBRE OS TELECONSULTORES',
    description: 'Teleconsultorias respondidas.',
    subtitle: 'Clique em DOWNLOAD para baixar uma planilha do Excel com os seguintes microdados: (1) teleconsultor respondente; (2) CBO do teleconsultor respondente; (3) especialidade da teleconsultoria; (4) orientação do teleconsultor respondente; (5) avaliação da resposta; (6) prazo (em dias) para a resposta; (6) CID-10 registrada; (7) CIAP-2 registrada.',
    nomeArquivo: '7. PLANILHA COM DADOS ADICIONAIS SOBRE OS TELECONSULTORES',
    type: 'File',
    addAttribute: {
      genDimensions: ['prazo (dias) para a resposta'],
      valueFn: (data_atendimento, data_solicitacao) => {
        if (!data_atendimento) return ['Não se aplica'];
        if (!data_solicitacao) return ['Sem data de solicitação.'];
        const diffDays = Math.round((data_atendimento - data_solicitacao) / 86400000);
        return [diffDays];
      },
      attributes: ['data_atendimento', 'data_solicitacao']
    },
    selectDimensions: ['id', 'nome_teleconsultor', 'cbo_familia_teleconsultor', 'cbo_ocupacao_teleconsultor', 'especificacao', 'orientacao_especifica', 'satisfacao_geral', 'prazo (dias) para a resposta', 'cid10', 'ciap2'],
    customHeader: ['ID da solicitação', 'Teleconsultor respondente', 'CBO família do teleconsultor respondente', 'CBO ocupação do teleconsultor respondente', 'Especialidade da teleconsultoria', 'Orientação do teleconsultor respondente', 'Avaliação da resposta', 'Prazo (em dias) para a resposta', 'CID-10 registrada', 'CIAP-2 registrada'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }, {
      attribute: 'nome_teleconsultor',
      filter: value => value != 'Não preenchido'
    }],
    transformations: []
  }, {
    title: '8. PLANILHA COM DADOS ADICIONAIS SOBRE AS SOLICITAÇÕES DE TELECONSULTORIA',
    description: 'Teleconsultorias respondidas.',
    subtitle: 'Clique em DOWNLOAD para baixar uma planilha do Excel com os seguintes microdados: (1) identificação da solicitação; (2) profissional solicitante; (3) CBO do profissional solicitante; (4) unidade de saúde do profissional solicitante; (5) especialidade solicitada; (6) orientação do teleconsultor; (7) resposta lida pelo solicitante; (8) nome, cpf e cns do paciente vinculado; (9) rede de teleconsultoria.',
    nomeArquivo: '8. PLANILHA COM DADOS ADICIONAIS SOBRE AS SOLICITAÇÕES DE TELECONSULTORIA',
    type: 'File',
    selectDimensions: ['id', 'nome_solicitante', 'cbo_familia_solicitante', 'cbo_ocupacao_solicitante', 'nome_instituicao', 'especificacao', 'orientacao_especifica', 'lida_solicitante', 'nome_paciente', 'cns_paciente', 'cpf_paciente', 'rede_teleconsultoria'],
    customHeader: ['ID da solicitação', 'Profissional solicitante', 'CBO família do profissional solicitante', 'CBO ocupação do profissional solicitante', 'Unidade de saúde do profissional solicitante', 'Especialidade solicitada', 'Orientação do teleconsultor respondente', 'Resposta lida pelo solicitante', 'Nome do paciente', 'CNS do paciente', 'CPF do paciente', 'Rede de teleconsultoria'],
    filters: [{
      attribute: 'teleconsultoria_finalizada',
      filter: value => value == 'Sim'
    }],
    transformations: []
  }],
  metaAtributos: [{
    atributo: '__time',
    grupo: '0.0 - Variáveis gerais',
    rotulo: 'Data da solicitação',
    tipo: 'DATA_HORA'
  }, {
    atributo: 'data_atendimento',
    grupo: '0.0 - Variáveis gerais',
    rotulo: 'Data da resposta',
    tipo: 'TIMESTAMP_MS'
  }, {
    atributo: 'rede_teleconsultoria',
    grupo: '1.0 - Dados básicos',
    rotulo: 'Rede teleconsultoria',
    tipo: 'TEXTO'
  }, {
    atributo: 'cidade',
    grupo: '1.0 - Dados básicos',
    rotulo: 'ESF: município',
    tipo: 'TEXTO'
  }, {
    atributo: 'estado',
    grupo: '1.0 - Dados básicos',
    rotulo: 'ESF: UF',
    tipo: 'TEXTO'
  }, {
    atributo: 'classificacao',
    grupo: '1.0 - Dados básicos',
    rotulo: 'Classificação da solicitação',
    tipo: 'TEXTO'
  }],
  filtros: [{
    key: 1,
    disabled: false,
    atributosPermitidos: ['data_atendimento', '__time'],
    atributo: 'data_atendimento',
    tipoComponente: 'BETWEEN_INCLUSIVE_TIMESTAMP_MS',
    valor: () => {
      const end = moment().set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).toDate();
      const start = moment().set({
        minute: 0,
        second: 0,
        millisecond: 0
      }).subtract(1, 'month').toDate();
      return [start, end];
    }
  }, {
    key: 2,
    disabled: true,
    hidden: true,
    atributo: 'tipo_regulacao',
    tipoComponente: 'IN_SELECT',
    valor: 'Municipal'
  }, {
    key: 3,
    disabled: true,
    habilitarRegraVinculo: true,
    atributo: 'rede_teleconsultoria',
    dependsOnWithValue: [{
      atributo: 'tipo_regulacao',
      tipoComponente: 'IN_SELECT',
      valor: 'Municipal'
    }],
    rotulo: 'Rede teleconsultoria',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }, {
    key: 4,
    disabled: true,
    habilitarRegraVinculo: true,
    atributo: 'estado',
    rotulo: 'Estado',
    tipoComponente: 'IN_SELECT',
    valor: ''
  }, {
    key: 5,
    disabled: true,
    habilitarRegraVinculo: true,
    atributo: 'cidade',
    rotulo: 'Município',
    dependsOn: ['estado'],
    tipoComponente: 'IN_SELECT',
    valor: ''
  }
  // {
  //     key: 5,
  //     disabled: true,
  //     atributo: 'classificacao',
  //     rotulo: 'Classificação',
  //     tipoComponente: 'IN_SELECT',
  //     valor: '',
  // },
  ]
};