import React, { useState, useEffect, useRef, useContext, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import PivotTableUI from '@stt-analise/pivottable';
import TableRenderers from '@stt-analise/pivottable/TableRenderers';
import Plot from 'react-plotly.js';
import createPlotlyRenderers from '@stt-analise/pivottable/PlotlyRenderers';
import { useMutation, useLazyQuery, useQuery } from '@apollo/client';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';

import { Grid, Container, IconButton, ListSubheader, ButtonGroup } from '@material-ui/core';
import { AddCircle, RemoveCircle } from '@material-ui/icons';
import { SttButton, SttSelect, SttMenuItem, SttModal, SttInput, SttLoading } from '@stt-componentes/core';
import Fieldset from '../../../components/Fieldset/Fieldset';

import { ADICIONAR_RELATORIO, GET_RELATORIO, GET_META_ATRIBUTOS, GET_CUBES } from '../../../requests/graphql';
// styles
import useStyles from '../../../themes/styles';
import '@stt-analise/pivottable/pivottable.css';

import { TabelaDadosRules, FiltroUtil, MSGUtil } from '@stt-analise/util';
import { useFormik } from 'formik';
import { FiltroSecundario } from '../../../components/Filter/FiltroSecundario';
import { SttTranslateHook } from '@stt-componentes/core';
import ExportContainer from '../../../components/ExportContainer';
import ExportarDadosFrontendParaPDF from 'src/components/Export/ExportarDadosFrontendParaPDF';
import exportarDadosFrontendParaXLSX from 'src/components/Export/exportarDadosFrontendParaXLSX';

import ModalSelecaoDeTabelas from '../../../components/ModalSelecaoDeTabelas';
import MaterialReactTable from '../../../components/Dashboard/Table/MaterialReactTable';
import MapRenderer from '../../../components/Dashboard/Map/MapRenderer';
import MuiAgg from '../../../components/Pivottable/MuiAgg';
import CheckboxTreeSelect from '../../../components/CheckboxTreeSelect/CheckboxTreeSelect';
import { MSGBuilder, MSGCode } from '@stt-analise/util';
import TitleCube from '../../../components/Dashboard/Title/TitleCube';
import useReqExportar from 'src/requests/rest/useReqExportar';
import useReqPesquisar from 'src/requests/rest/useReqPesquisar';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';
import { triggerAlert } from 'src/signals/alertaSignal';

// create Plotly renderers via dependency injection
const PlotlyRenderers = createPlotlyRenderers(Plot);

const getParams = (location) => {
    const titulo = new URLSearchParams(location.search).get('titulo');
    return { titulo };
};

const normalizePayload = ({ cubo, atributos, filtros, agrupado }, metaAtributos) => {
    const nFiltros = FiltroUtil.normalizeFiltros(filtros);
    const [dimensoes, medidas] = TabelaDadosRules.normalizeDimensoesAndMedidas(cubo, atributos, metaAtributos, agrupado);
    return {
        cubo,
        atributos: dimensoes,
        filtros: nFiltros,
        medidas,
        agrupado,
    };
};

function TabelaDados() {
    const classes = useStyles();
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const location = useLocation();
    const { titulo } = getParams(location);
    const inputNomeRelatorio = useRef(null);

    const [nomeRelatorio, setNomeRelatorio] = useState(titulo);
    const [isSave, setIsSave] = useState(false);

    const [carregarDataset, setCarregarDataset] = useState(0);
    const [pivotState, setPivotState] = useState(null);

    const [openModal, setOpenModal] = useState(false);
    const [openExportModal, setOpenExportModal] = useState(false);

    const [openModalTable, setOpenModalTable] = useState(false);
    const [disabledCheckboxAgrupamento, setDisabledCheckboxAgrupamento] = useState(false);

    const [loadingExpDados, setLoadingExpDados] = useState(false);
    const referenciaTabelaNormal = useRef(null);
    const [open, setOpen] = React.useState(false);
    const anchorRef = React.useRef(null);
    const [selectedIndex, setSelectedIndex] = React.useState(0);

    // Formulário
    const formik = useFormik({
        initialValues: {
            cubo: '',
            atributosSelecionados: [],
            filtros_secundarios: [], // key, atributo, valor, tipoComponente
            tipo: TabelaDadosRules.TipoTabela.TABELA_DINAMICA,
            habilitarNumerosDeLinha: false,
            habilitarAgrupamento: false,
        },
        onSubmit: (values) => {
            const payload = normalizePayload(
                {
                    cubo: values.cubo,
                    atributos: values.atributosSelecionados,
                    filtros: values.filtros_secundarios,
                    agrupado: values.habilitarAgrupamento,
                },
                metaAtributos
            );

            executarConsulta(payload);
        },
    });

    // Request via graphql
    const { loading, error, data: dataGetCubes } = useQuery(GET_CUBES, { fetchPolicy: 'no-cache' });
    const [requestMetaAtributos, { loading: loadingMetaAtributos, data: resultMetaAtributos }] = useLazyQuery(GET_META_ATRIBUTOS);
    const [requestRelatorio, { data: dataRelatorio, loading: loadingRelatorio }] = useLazyQuery(GET_RELATORIO, { fetchPolicy: 'no-cache' });
    const [insertRelatorio, { loading: loadingInsertRelatorio }] = useMutation(ADICIONAR_RELATORIO);
    const loadedMetaAtributos = resultMetaAtributos == undefined || loadingMetaAtributos;
    const metaAtributos = resultMetaAtributos ? resultMetaAtributos['iMetaAtributosPivottable'] : [];
    const metaAtributosSelecionados = metaAtributos.filter((option) => formik.values.atributosSelecionados.includes(option.atributo));
    const hasAnexo = () => dataRelatorio && dataRelatorio.iRelatorio && dataRelatorio.iRelatorio.anexo;
    const { loading: loadingExportar, reqExportar } = useReqExportar();
    const { data: datasetServer, reqPesquisar, loading: loadingPesquisar, resetDataset } = useReqPesquisar();

    const executarConsulta = async (payload) => {
        const successCb = async () => {
            if (TabelaDadosRules.validarExecutarConsulta(payload.cubo)) {
                resetDataset();
                reqPesquisar({ payload, triggerAlert, metaAtributos, strings });
            } else {
                const msgAlert = new MSGBuilder(MSGCode.ERR001, strings).buildMsgAlert();
                triggerAlert(msgAlert);
            }
        };

        // Exibir mensagem de alerta para usuário que não colocou filtro.
        if (TabelaDadosRules.naoExisteFiltros(payload.filtros)) {
            const msgAlert = new MSGBuilder(MSGCode.CON001, strings).buildMsgAlert();
            triggerAlert(msgAlert, successCb);
        } else {
            successCb();
        }
    };

    const downloadFile = (tipo) => {
        const payload = normalizePayload(
            {
                cubo: formik.values.cubo,
                atributos: formik.values.atributosSelecionados,
                filtros: formik.values.filtros_secundarios,
                agrupado: formik.values.habilitarAgrupamento,
            },
            metaAtributos
        );

        const successCb = () => {
            if (TabelaDadosRules.validarExecutarConsulta(payload.cubo)) {
                const nomeArquivo = `${nomeRelatorio || payload.cubo}`;
                reqExportar({ nomeArquivo, tipoArquivo: tipo, payload });
            }
        };

        // Exibir mensagem de alerta para usuário que não colocou filtro.
        if (TabelaDadosRules.naoExisteFiltros(payload.filtros)) {
            const msgAlert = new MSGBuilder(MSGCode.CON001, strings).buildMsgAlert();
            triggerAlert(msgAlert, successCb);
        } else {
            successCb();
        }
    };

    /** RELATORIO: Carregar os metadados de um relatório salvo */
    useEffect(() => {
        if (hasAnexo()) {
            formik.setValues({
                ...formik.values,
                cubo: dataRelatorio.iRelatorio.anexo.cubo || '',
                tipo: dataRelatorio.iRelatorio.tipo || TabelaDadosRules.TipoTabela.TABELA_DINAMICA,
                atributosSelecionados: dataRelatorio.iRelatorio.anexo.atributos,
                filtros_secundarios: (dataRelatorio.iRelatorio.anexo.filtros_secundarios || []).map((filtro, index) => ({ ...filtro, key: index })), // Inserir uma key: index, para todos os filtros_secundarios;
                habilitarNumerosDeLinha: dataRelatorio.iRelatorio.anexo.habilitarNumerosDeLinha,
                habilitarAgrupamento: dataRelatorio.iRelatorio.anexo.habilitarAgrupamento,
            });
            requestMetaAtributos({ variables: { cubo: dataRelatorio.iRelatorio.anexo.cubo || '' }, fetchPolicy: 'no-cache' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRelatorio]);

    /** RELATORIO: Executar novamente */
    useEffect(() => {
        // @TODO criar uma tarefa para definir quais cubos precisam de autoexecute ao abrir relatório.
        const AUTO_EXECUTE = false;
        if (hasAnexo() && metaAtributos.length > 0 && AUTO_EXECUTE) {
            let filtros_secundarios;
            if (dataRelatorio.iRelatorio.anexo.filtros_secundarios) filtros_secundarios = dataRelatorio.iRelatorio.anexo.filtros_secundarios || [];
            executarConsulta(dataRelatorio.iRelatorio.anexo.cubo, dataRelatorio.iRelatorio.anexo.atributos || [], filtros_secundarios);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataRelatorio, metaAtributos]);

    useEffect(() => {
        if (titulo) {
            requestRelatorio({ variables: { username: usuarioSignal.value.login, titulo }, fetchPolicy: 'no-cache' });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [titulo]);

    useEffect(() => {
        if (formik.values.cubo && TabelaDadosRules.isSempreAgrupado(formik.values.cubo)) {
            formik.setValues({ ...formik.values, habilitarAgrupamento: true });
            setDisabledCheckboxAgrupamento(true);
        } else if (formik.values.cubo && !TabelaDadosRules.isSempreAgrupado(formik.values.cubo)) {
            setDisabledCheckboxAgrupamento(false);
        }
    }, [formik.values.cubo]);

    // Validar pivottable
    useEffect(() => {
        if (datasetServer.length > 1) {
            if (hasAnexo()) {
                const { rendererName, rows, rowOrder, cols, colOrder, aggregatorList } = dataRelatorio.iRelatorio.anexo;
                let pivotStateExtend = {};
                if (rowOrder) {
                    pivotStateExtend['rowOrder'] = rowOrder;
                }
                if (colOrder) {
                    pivotStateExtend['colOrder'] = colOrder;
                }
                const pivotState = { ...pivotStateExtend, rendererName, rows: rows || [], cols: cols || [], aggregatorList: aggregatorList || [] };
                setPivotState(pivotState);
            } else {
                const { pivotState } = TabelaDadosRules.getPropsUI(formik.values.cubo);
                setPivotState({ ...pivotState });
            }
            setCarregarDataset(Date.now());
        } else {
            setCarregarDataset(0);
        }
    }, [datasetServer]);

    function handleOpen() {
        setOpenModal(true);
    }

    function handleClose() {
        if (!isSave) setNomeRelatorio(titulo);
        setOpenModal(false);
    }

    function handleExportClose() {
        setOpenExportModal(false);
    }

    function handleExportOpen() {
        setOpenExportModal(true);
    }

    function handleTabelaClose() {
        setOpenModalTable(false);
    }

    const handleChangeCube = async (event) => {
        const cubeName = event.target.value;
        const { pivotState } = TabelaDadosRules.getPropsUI(cubeName);

        const filtros_secundarios = TabelaDadosRules.getDefaultFiltros(cubeName);
        formik.setValues({ ...formik.values, atributosSelecionados: [], filtros_secundarios, cubo: event.target.value });

        requestMetaAtributos({ variables: { cubo: event.target.value }, fetchPolicy: 'no-cache' });
        resetDataset();

        if (pivotState) {
            setPivotState({ ...pivotState });
        } else {
            setPivotState(null);
        }
    };

    const adicionarFiltro = async () => {
        formik.setValues({
            ...formik.values,
            filtros_secundarios: [
                ...formik.values.filtros_secundarios,
                {
                    key: new Date().getTime(),
                    atributo: '',
                    disabled: false,
                    tipoComponente: '',
                    valor: '',
                },
            ],
        });
    };

    const removerFiltro = async () => {
        const novoFiltros = [...formik.values.filtros_secundarios];
        novoFiltros.pop();
        formik.setValues({
            ...formik.values,
            filtros_secundarios: novoFiltros,
        });
    };

    const handleSalvarRelatorio = async () => {
        setOpenModal(false);
        if (nomeRelatorio) {
            let anexo = {
                cubo: formik.values.cubo,
                atributos: formik.values.atributosSelecionados,
                filtros_secundarios: formik.values.filtros_secundarios,
                habilitarNumerosDeLinha: formik.values.habilitarNumerosDeLinha,
                habilitarAgrupamento: formik.values.habilitarAgrupamento,
            };
            if (pivotState)
                anexo = {
                    ...anexo,
                    rows: pivotState.rows,
                    rowOrder: pivotState.rowOrder,
                    cols: pivotState.cols,
                    colOrder: pivotState.colOrder,
                    rendererName: pivotState.rendererName,
                    aggregatorList: pivotState.aggregatorList?.map((agg) => ({ aggTitle: agg.aggTitle, vals: agg.vals, aggregatorName: agg.aggregatorName })),
                };

            try {
                const { data: result } = await requestRelatorio({
                    variables: { username: usuarioSignal.value.login, titulo: nomeRelatorio },
                    fetchPolicy: 'no-cache',
                });
                const existeRelatorio = result['iRelatorio'] != null;

                const msgAlert = new MSGBuilder(MSGCode.CON003, strings).buildMsgAlert();
                const yesOrNo = await MSGUtil.confirmationFuncWithRule(msgAlert, !existeRelatorio, triggerAlert);
                if (!yesOrNo) return;

                await insertRelatorio({
                    variables: {
                        _id: dataRelatorio?.iRelatorio?._id,
                        titulo: nomeRelatorio,
                        username: usuarioSignal.value.login,
                        tipo: formik.values.tipo,
                        anexo,
                    },
                });
                triggerAlert(new MSGBuilder(MSGCode.SUC001, strings).buildMsgAlert());
                setIsSave(true);
            } catch ({ graphQLErrors, message }) {
                const msgCode = graphQLErrors[0]?.extensions.msgCode || MSGCode.ERR001;
                const msgAlert = new MSGBuilder(msgCode, strings).buildMsgAlert();
                triggerAlert(msgAlert);
            }
        }
    };

    const selectItemsCubos = useMemo(() => {
        // 1 - RN: Cubos permitidos
        const cubos = (dataGetCubes?.iCubos && TabelaDadosRules.filtrarCubosPermitidos(dataGetCubes.iCubos, usuarioSignal.value.rbac)) || [];

        // 2 - Preparar agrupado para renderizar no Select
        const items = [];
        const agruparCubos = cubos.reduce((acc, current) => {
            if (!acc[current.grupo || strings.TabelaDados.outrosCubos]) acc[current.grupo || strings.TabelaDados.outrosCubos] = [];
            acc[current.grupo || strings.TabelaDados.outrosCubos].push(current);
            return acc;
        }, {});
        Object.keys(agruparCubos).forEach((key) => {
            items.push({ divider: true, grupo: key });
            agruparCubos[key].forEach(({ dataset, rotulo }) => {
                items.push({ divider: false, dataset, rotulo });
            });
        });

        return items;
    }, [dataGetCubes]);

    const enviarEventoExportarPDF = () => {
        if (referenciaTabelaNormal.current) {
            referenciaTabelaNormal.current.enviarEventoExportarPDF();
        }
    };

    const exportarPDF = () => {
        setLoadingExpDados(true);
        if (formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA) {
            ExportarDadosFrontendParaPDF();
        } else {
            enviarEventoExportarPDF();
        }
        setLoadingExpDados(false);
    };

    // Lista de opções
    let opcoesDeExportacao = ['Exportar', 'Exportar XLSX'];
    if (global.gConfig.config_id === 'development') opcoesDeExportacao = ['Exportar', 'Exportar XLSX', 'Exportar PDF'];

    const handleClick = () => {
        switch (selectedIndex) {
            case 0:
                setOpenExportModal(true);
                break;
            case 1:
                if (formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA) {
                    setLoadingExpDados(true);
                    exportarDadosFrontendParaXLSX();
                    setLoadingExpDados(false);
                }
                break;
            case 2:
                exportarPDF();
                break;
            default:
                break;
        }
    };

    const handleMenuItemClick = (event, index) => {
        setSelectedIndex(index);
        setOpen(false);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleCloseExport = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };

    return (
        <>
            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <TitleCube title={nomeRelatorio} cubo={formik.values.cubo} />
                </Grid>

                <Fieldset title={strings.Comuns.selecioneCubo} />
                <Grid item xs={12} sm={12} md={12}>
                    {!loading && !error && (
                        <SttSelect label="Cubo" value={formik?.values?.cubo || ''} onChange={handleChangeCube}>
                            {selectItemsCubos.map(({ divider, grupo, dataset, rotulo }, index) => {
                                return divider ? (
                                    <ListSubheader key={index} style={{ paddingLeft: '10px', lineHeight: '15px', paddingTop: '10px' }}>
                                        {grupo}
                                    </ListSubheader>
                                ) : (
                                    <SttMenuItem key={dataset} value={dataset}>
                                        {rotulo || dataset}
                                    </SttMenuItem>
                                );
                            })}
                        </SttSelect>
                    )}
                    {loading && <span>Carregando!</span>}
                    {error && <span>Erro!</span>}
                </Grid>

                <Fieldset title={strings.Comuns.adicioneFiltros} />
                <Grid item xs={12} sm={12} md={12} lg={12}>
                    <FiltroSecundario
                        filtros={formik.values.filtros_secundarios}
                        cubo={formik.values.cubo}
                        metaAtributos={metaAtributos}
                        loadingMetaAtributos={loadingMetaAtributos}
                        onChangeFilter={(filtroAlterado) => {
                            const novoFiltrosSecundarios = formik.values.filtros_secundarios.map((filtro) => {
                                if (filtro.key == filtroAlterado.key) {
                                    return filtroAlterado;
                                }
                                return filtro;
                            });
                            formik.setValues({
                                ...formik.values,
                                filtros_secundarios: novoFiltrosSecundarios,
                            });
                        }}
                    />
                </Grid>
                <Grid container>
                    <IconButton
                        onClick={adicionarFiltro}
                        color="primary"
                        aria-label={strings.Comuns.adicionarFiltro}
                        disabled={TabelaDadosRules.disabledFilter(formik.values.cubo)}
                        component="span"
                    >
                        <AddCircle fontSize="large" />
                    </IconButton>
                    <IconButton
                        onClick={removerFiltro}
                        color="primary"
                        aria-label={strings.Comuns.removerFiltro}
                        disabled={TabelaDadosRules.disabledFilter(formik.values.cubo, formik.values.filtros_secundarios)}
                        component="span"
                    >
                        <RemoveCircle fontSize="large" />
                    </IconButton>
                </Grid>

                <Fieldset title={strings.Comuns.selecioneVariaveis} />
                <Grid item xs={12}>
                    <CheckboxTreeSelect
                        disabled={loadedMetaAtributos}
                        atributos={metaAtributos}
                        atributosEstaticos={TabelaDadosRules.getPropsUI(formik.values.cubo)?.atributosEstaticos || []}
                        checked={metaAtributosSelecionados.map((attr) => attr.atributo)}
                        disabledPrefixGrupo={TabelaDadosRules.getPropsUI(formik.values.cubo)?.disabledPrefixGrupo || false}
                        setChecked={(checked_) => {
                            formik.setValues({
                                ...formik.values,
                                atributosSelecionados: checked_,
                            });
                        }}
                    />
                </Grid>

                {/* Botões */}
                <Grid item xs={16} sm={16} md={24}>
                    <Grid container>
                        <Grid item>
                            <SttButton
                                disabled={formik.values.atributosSelecionados.length === 0}
                                className={classes.button}
                                variant="contained"
                                color="primary"
                                onClick={() => {
                                    setOpenModalTable(true);
                                }}
                            >
                                {strings.Comuns.executarConsulta}
                            </SttButton>
                        </Grid>
                        <Grid item>
                            <SttButton className={classes.button} variant="contained" color="primary" onClick={handleOpen}>
                                {strings.Comuns.salvarRelatorio}
                            </SttButton>
                        </Grid>
                        <Grid>
                            <ButtonGroup ref={anchorRef} className={classes.button}>
                                <SttButton
                                    disabled={formik.values.atributosSelecionados.length === 0 || !carregarDataset}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleClick}
                                >
                                    {opcoesDeExportacao[selectedIndex]}
                                </SttButton>
                                <SttButton
                                    disabled={formik.values.atributosSelecionados.length === 0 || !carregarDataset}
                                    variant="contained"
                                    color="primary"
                                    onClick={handleToggle}
                                >
                                    <ArrowDropDownIcon />
                                </SttButton>
                            </ButtonGroup>
                            <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 1300 }}>
                                {({ TransitionProps, placement }) => (
                                    <Grow
                                        {...TransitionProps}
                                        style={{
                                            transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                                        }}
                                    >
                                        <Paper>
                                            <ClickAwayListener onClickAway={handleCloseExport}>
                                                <MenuList id="split-button-menu">
                                                    {opcoesDeExportacao.map((option, index) => (
                                                        <MenuItem
                                                            key={option}
                                                            selected={index === selectedIndex}
                                                            onClick={(event) => handleMenuItemClick(event, index)}
                                                        >
                                                            {option}
                                                        </MenuItem>
                                                    ))}
                                                </MenuList>
                                            </ClickAwayListener>
                                        </Paper>
                                    </Grow>
                                )}
                            </Popper>
                        </Grid>
                    </Grid>
                </Grid>

                {/* Tabela resultado */}
                {!!carregarDataset && (
                    <Grid item xs={12} className={classes.pivottable} key={carregarDataset}>
                        {formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_DINAMICA && (
                            <PivotTableUI
                                // hiddenAttributes={hiddenAttributes}
                                data={datasetServer}
                                meta={metaAtributos}
                                enableRowNumbers={formik.values.habilitarNumerosDeLinha}
                                unusedOrientationCutoff={Infinity}
                                renderers={Object.assign({}, TableRenderers, PlotlyRenderers, {
                                    Mapa: MapRenderer,
                                })}
                                onChange={(s) => setPivotState(s)}
                                {...pivotState}
                                ComponentAgg={MuiAgg}
                            />
                        )}
                        {formik.values.tipo === TabelaDadosRules.TipoTabela.TABELA_NORMAL && (
                            <MaterialReactTable
                                ref={referenciaTabelaNormal} // Adicione esta linha
                                data={datasetServer}
                                meta={metaAtributosSelecionados}
                                enableRowNumbers={formik.values.habilitarNumerosDeLinha}
                            />
                        )}
                    </Grid>
                )}
            </Grid>
            <SttModal
                title={strings.Comuns.salvarRelatorio}
                open={openModal}
                iconClose={handleClose}
                outModalClose={handleClose}
                footer={
                    <div>
                        <SttButton variant="contained" color="primary" onClick={handleSalvarRelatorio}>
                            {strings.Comuns.salvar}
                        </SttButton>
                        <SttButton variant="contained" color="primary" onClick={handleClose}>
                            {strings.Comuns.cancelar}
                        </SttButton>
                    </div>
                }
            >
                <Container maxWidth="md">
                    <SttInput
                        autoFocus
                        value={nomeRelatorio}
                        onChange={(e) => {
                            setNomeRelatorio(e.target.value);
                        }}
                        style={{ minWidth: '300px' }}
                        ref={inputNomeRelatorio}
                        label={strings.Comuns.nomeRelatorio}
                        required
                    />
                </Container>
            </SttModal>
            <SttModal title={strings.Comuns.exportar} open={openExportModal} iconClose={handleExportClose} outModalClose={handleExportClose}>
                <Container maxWidth="md">
                    <ExportContainer
                        onClickExport={(tipo) => {
                            downloadFile(tipo);
                            handleExportClose();
                        }}
                    />
                </Container>
            </SttModal>
            <SttModal
                className={classes.dialogContent}
                title="Escolha o tipo de visualização"
                open={openModalTable}
                iconClose={handleTabelaClose}
                outModalClose={handleTabelaClose}
            >
                <Container maxWidth="md">
                    <ModalSelecaoDeTabelas
                        tipo={formik.values.tipo}
                        habilitarNumerosDeLinha={formik.values.habilitarNumerosDeLinha}
                        habilitarAgrupamento={formik.values.habilitarAgrupamento}
                        disabledCheckboxAgrupamento={disabledCheckboxAgrupamento}
                        onClickConfirmar={(tipo, habilitarNumerosDeLinha, habilitarAgrupamento) => {
                            formik.setFieldValue('tipo', tipo);
                            formik.setFieldValue('habilitarNumerosDeLinha', habilitarNumerosDeLinha);
                            formik.setFieldValue('habilitarAgrupamento', habilitarAgrupamento);
                            formik.handleSubmit();
                            handleTabelaClose();
                        }}
                        onClickCancelar={() => {
                            handleTabelaClose();
                        }}
                    />
                </Container>
            </SttModal>
            <SttLoading
                text={strings.Comuns.carregando}
                open={
                    loadingPesquisar ||
                    loadingMetaAtributos ||
                    loadingInsertRelatorio ||
                    loadingRelatorio ||
                    loadingExportar ||
                    loadingExpDados
                }
            />
        </>
    );
}

export default TabelaDados;
