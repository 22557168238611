import { SPLIT_CHAR_SPECIAL, CONCAT_STR_AGG } from '../constants/Split';
import DataManipulation from './DataManipulation';
import _ from 'lodash';
class DatasetDruid {
  constructor(dataset) {
    this.dataset = dataset;
  }
  selectDimensions(dimensions) {
    this.dimensions = dimensions;
    if (Array.isArray(dimensions) && dimensions.length > 0) this.dataset = DataManipulation.selectDimensions(this.dataset, dimensions);else console.log('args inválido função selectDimensions(dimensions)');
    return this;
  }
  reduceDimensions(dimensions) {
    this.dimensions = dimensions;
    if (Array.isArray(dimensions) && dimensions.length > 0) this.dataset = DataManipulation.selectDimensions(this.dataset, dimensions);else console.log('args inválido função reduceDimensions(dimensions)');
    return this;
  }
  applyTransformations(transformations) {
    if (Array.isArray(transformations) && transformations.length > 0) this.dataset = DataManipulation.applyTransformationDimensions(this.dataset, transformations);else console.log('args inválido função applyTransformations(transformations)');
    return this;
  }
  applyFilters(filters) {
    if (Array.isArray(filters) && filters.length > 0) this.dataset = DataManipulation.applyFilterDimensions(this.dataset, filters);
    return this;
  }
  /**
   * Funciona para M dimensões.
   * input: N rows e M columns
   * output: <N rows e M columns (a última coluna será a função agregadora com seu alias).
   *
   * @param {alias?, attribute, operation, stringAggColumnNames?} groupBy
   * @returns
   */
  groupByV2(groupBy) {
    var _a;
    if (!groupBy || !groupBy.attribute || !groupBy.operation) return this;
    if (groupBy && ((_a = groupBy.stringAggColumnNames) === null || _a === void 0 ? void 0 : _a.length) > 0) this.dataset = DataManipulation.groupByWithStringAgg(this.dataset, groupBy);else if (groupBy) this.dataset = DataManipulation.groupBy(this.dataset, groupBy);
    return this;
  }
  /**
   * 1. última dim precisa ser uma métrica.
   * 2. quando tiver strAgg, penultimo indice precisa ser o valor de StrAgg.
   */
  groupBy(_ref) {
    let {
      filterAgg = () => true,
      op,
      strAgg
    } = _ref;
    const METRIC_INDEX_SLICE = -1;
    const STRAGG_INDEX_SLICE = -2;
    if (!op) return this;
    if (this.dataset.length <= 1) {
      return this;
    }
    if (this.dataset[0].length <= 1) {
      console.warn('Verificar implementação, não é possível utilizar groupby apenas com uma dimensão.');
      return this;
    }
    const opFn = {
      'sum': (ref, metric) => ref ? ref + metric : metric,
      'distinct': (ref, metric) => 1,
      'avg': (ref, metric) => ref ? [ref[0] + metric, ref[1] + 1] : [metric, 1]
    };
    const opStr = {
      'default': (ref, dimStrAgg) => ref ? [...ref, dimStrAgg] : [dimStrAgg]
    };
    let buffer = this.dataset.slice(1).reduce((acc, curr) => {
      let dimensionsList, dimStrAgg;
      if (strAgg) {
        dimensionsList = curr.slice(0, STRAGG_INDEX_SLICE);
        [dimStrAgg] = curr.slice(STRAGG_INDEX_SLICE);
      } else {
        dimensionsList = curr.slice(0, METRIC_INDEX_SLICE);
      }
      const [metric] = curr.slice(METRIC_INDEX_SLICE);
      const key = dimensionsList.join(SPLIT_CHAR_SPECIAL);
      // Exmplo para <sum> => acc[key] = acc[key] ? acc[key] + metric : metric;
      if (strAgg) {
        acc['stringAgg'][key] = opStr['default'](acc['stringAgg'][key], dimStrAgg);
      }
      acc['metric'][key] = opFn[op](acc['metric'][key], metric);
      return acc;
    }, {
      'metric': {},
      'stringAgg': {}
    });
    // @TODO ainda falta fazer para média if(op === 'avg'){}
    let newDataset = [this.dimensions];
    for (const key in buffer['metric']) {
      if (Object.hasOwnProperty.call(buffer['metric'], key)) {
        const count = buffer['metric'][key];
        if (strAgg) {
          const dimStrAgg = [...new Set(buffer['stringAgg'][key])].join(CONCAT_STR_AGG);
          const dimensions = key.split(SPLIT_CHAR_SPECIAL);
          const metric = count;
          const added = filterAgg(metric);
          if (added) {
            const row = [...dimensions, dimStrAgg, metric];
            newDataset.push(row);
          }
        } else {
          const dimensions = key.split(SPLIT_CHAR_SPECIAL);
          const metric = count;
          const added = filterAgg(metric);
          if (added) {
            const row = [...dimensions, metric];
            newDataset.push(row);
          }
        }
      }
    }
    this.dataset = newDataset;
    return this;
  }
  /**
   * Aceita apenas duas dimensões
   * - A primeira dimensão precisa ser agrupada.
   * - A segunda dimensão precisa ser uma métrica
   */
  topGroup(_ref2) {
    let {
      limit,
      textOther = 'Outros'
    } = _ref2;
    const INDEX_LAST_DIM = 1;
    const INDEX_DATA_SLICE = 1;
    const INDEX_HEADER = 0;
    if (limit) {
      const dataOrder = _.orderBy(this.dataset.slice(INDEX_DATA_SLICE), [o => o[INDEX_LAST_DIM]], ['desc']);
      if (dataOrder.length > limit) {
        const sumOther = dataOrder.slice(limit).reduce((acc, cur) => acc + cur[INDEX_LAST_DIM], 0);
        this.dataset = [this.dataset[INDEX_HEADER], ...dataOrder.slice(0, limit), [textOther, sumOther]];
      } else {
        this.dataset = [this.dataset[INDEX_HEADER], ...dataOrder.slice(0, limit)];
      }
    }
    return this;
  }
  /**
   * Aceita N dimensões. Seu objetivo é duplicar registros a partir de uma dimensão.
   */
  flatMap(_ref3) {
    let {
      dimension,
      flatFn
    } = _ref3;
    if (!dimension || !flatFn) return this;
    if (this.dataset.length <= 1) return this;
    const sizeDimension = this.dataset[0].length;
    if (sizeDimension <= 1) throw new Error("Verificar implementa\xE7\xE3o, n\xE3o \xE9 poss\xEDvel utilizar flatMap apenas com uma dimens\xE3o.'");
    const indexFlatMap = this.dataset[0].findIndex(dim => dim === dimension);
    if (indexFlatMap === -1) throw new Error("".concat(dimension, " n\xE3o encontrada."));
    const duplicateRowsFn = row => {
      if (indexFlatMap === sizeDimension - 1) return str => [...row.slice(0, indexFlatMap), str, ...row.slice(indexFlatMap + 1)]; // // INDEX_FLAT_MAP == LEN_DIM - 1
      else if (indexFlatMap === 0) return str => [str, ...row.slice(1)]; // INDEX_FLAT_MAP == 0
      else return str => [...row.slice(0, indexFlatMap), str, ...row.slice(indexFlatMap + 1)]; // INDEX_FLAT_MAP == N
    };
    this.dataset = [this.dataset[0], ..._.flatMap(this.dataset.slice(1), row => {
      const strArr = flatFn(row[indexFlatMap]);
      if (strArr.length > 1) return strArr.map(duplicateRowsFn(row));else if (strArr.length === 1) return [row];else throw new Error('Implementação do flatFn precisa retornar um Array de 1 ou mais elementos.');
    })];
    return this;
  }
  assembleAttributes(_ref4) {
    let {
      genDimensions,
      valueFn,
      attributes
    } = _ref4;
    const header = [...genDimensions, ...this.dataset[0]];
    const newDataset = [header];
    const indexes = attributes.map(attribute => this.dataset[0].indexOf(attribute));
    this.dataset.slice(1).forEach(row => {
      const value = valueFn(...indexes.map(j => row[j]));
      if (!Array.isArray(value)) throw new Error("Erro de implementa\xE7\xE3o! O retorno do valor precisa ser um Array.");
      newDataset.push([...value, ...row]);
    });
    this.dimensions = header;
    this.dataset = newDataset;
    return this;
  }
  /**
   * Adiciona um ou mais atributos, permite transformar os valores adicionados.
   */
  addAttribute(_ref5) {
    let {
      genDimensions,
      valueFn,
      attributes
    } = _ref5;
    // Obrigatório que todos os campos estejam preenchidos.
    if (!genDimensions || !valueFn || !attributes) return this;
    // Verifica dataset vazio
    if (this.dataset.length <= 1) {
      return this;
    }
    if (Array.isArray(genDimensions)) {
      return this.assembleAttributes({
        genDimensions,
        valueFn,
        attributes
      });
    } else {
      throw new Error("Erro de implementa\xE7\xE3o! O atributo genDimensions precisa ser um Array.");
    }
  }
  addAttributes(attributes) {
    for (let index = 0; index < attributes.length; index++) {
      const attribute = attributes[index];
      this.addAttribute(attribute);
    }
    return this;
  }
  build() {
    return this.dataset;
  }
}
export default DatasetDruid;