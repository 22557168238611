var Permissao;
(function (Permissao) {
  // Módulo
  Permissao["ANALISE"] = "ANALISE_ANALISE";
  // Dashboards
  Permissao["ANALISE_ACESSAR_DASHBOARD_TELECONSULTORIA_MUNICIPAL"] = "ANALISE_DASHBOARD";
  Permissao["ANALISE_ACESSAR_DASHBOARD_TELECONSULTORIA_ESTADUAL"] = "ANALISE_ACESSAR_DBOARD_TGE";
  Permissao["ANALISE_ACESSAR_DASHBOARD_IT"] = "ANALISE_ACESSAR_DASHBOARD_IT";
  Permissao["ANALISE_ACESSAR_DASHBOARD_ALOFONO"] = "ANALISE_ACESSAR_DASHBOARD_AF";
  // Mapas Interativos - [M]apa de [M]onitoração de [E]ventos."
  Permissao["ANALISE_ACESSAR_MAPA_EVENTOS"] = "ANALISE_ACESSAR_MAPA_EVENTOS";
  Permissao["ANALISE_ACESSAR_MAPA_EVENTOS_DERMATO"] = "ANALISE_ACESSAR_MME_DERMATO";
  // Cubos TDC - [T]abela de [D]ados [C]ubo
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESTOMATO"] = "ANALISE_ACESSAR_TDC_ESTOMATO";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_DERMATO"] = "ANALISE_ACESSAR_TDC_DERMATO";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD"] = "ANALISE_ACESSAR_TDC_TFD";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TFD_PASSAGENS"] = "ANALISE_ACESSAR_TDC_TFDP";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_ECG"] = "ANALISE_ACESSAR_TDC_ECG";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_EEG"] = "ANALISE_ACESSAR_TDC_EEG";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELEDIAGNOSTICO"] = "ANALISE_ACESSAR_TDC_TDIAG";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_PACS"] = "ANALISE_ACESSAR_TDC_PACS";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_PONTOS"] = "ANALISE_ACESSAR_TDC_PONTOS";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS"] = "ANALISE_ACESSAR_TDC_TELE";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO"] = "ANALISE_ACESSAR_TDC_TELE_A";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES"] = "ANALISE_ACESSAR_TDC_FATIVIDA";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS"] = "ANALISE_ACESSAR_TDC_FAATIVOS";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_FUNCIONARIO"] = "ANALISE_ACESSAR_TDC_FFUNCION";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_TELEATENDIMENTO"] = "ANALISE_ACESSAR_TDC_TATEN";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_ESPIRO"] = "ANALISE_ACESSAR_TDC_ESPIRO";
  Permissao["ANALISE_ACESSAR_TABELA_DADOS_CUBO_POLISSONO"] = "ANALISE_ACESSAR_TDC_POLISSON";
  // Tela Administrativa
  Permissao["ANALISE_ACESSAR_ADMINISTRATIVO_AGENDAMENTO"] = "ANALISE_ACESSAR_ADM_AGENDAME";
  // Tela Administrativo Tabela de Dados
  //ANALISE_ACESSAR_ADMINISTRATIVO_TABELA_DE_DADOS = 'ANALISE_ACESSAR_ADM_TABELA'
})(Permissao || (Permissao = {}));
export default Permissao;