import React, { useState, useContext } from 'react';
import { AppBar } from '@material-ui/core';

import {
    SttHeader,
    MateriaisSuporte,
    SttMainNavigationBar,
    SttNotificationScreen,
    SttSplashScreen,
    SttTranslateHook,
    SttHelpdeskShortcut,
} from '@stt-componentes/core';
import { Cache } from '@stt-componentes/cache';

// styles
import useStyles from './styles';
import SystemConstants from '../../util/SystemConstants';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';

const makeSubmodulos = (history, submodulos) => {
    const pages = [SystemConstants.HOME, ...submodulos];

    let submodulosInner = [];
    let submodulosInnerAgrupados = {};
    pages.forEach((page) => {
        if (page.textParent && submodulosInnerAgrupados[page.textParent] == undefined) {
            submodulosInnerAgrupados[page.textParent] = {
                order: page.id,
                id: page.path,
                text: page.textParent,
                list: [
                    {
                        text: page.text,
                        onClick: () => {
                            history?.push(page.path);
                        },
                    },
                ],
            };
        } else if (page.textParent && submodulosInnerAgrupados[page.textParent] != undefined) {
            submodulosInnerAgrupados[page.textParent].list.push({
                text: page.text,
                onClick: () => {
                    history?.push(page.path);
                },
            });
        } else
            submodulosInner.push({
                order: page.id,
                id: page.path,
                text: page.text,
                button: true,
                onClick: () => {
                    history?.push(page.path);
                },
            });
    });

    const nSubmodulos = [...submodulosInner, ...Object.values(submodulosInnerAgrupados)];

    return nSubmodulos.sort((a, b) => {
        return Number(a.order) - Number(b.order);
    });
};

const findSubmenuAtivo = ({ history, submodulos, submenus }) => {
    let submenuAtivo = 0;
    if (submodulos && submodulos.length === 0) return submenuAtivo;
    const submodulo = submodulos.find((submodulo) => submodulo.path === history?.location?.pathname);
    if (submodulo) {
        const index = submenus.findIndex((menu) => submodulo.textParent === menu.text || submodulo.text === menu.text);
        submenuAtivo = index !== -1 ? index : 0;
    }
    return submenuAtivo;
};

const historyListen = ({ setSubmenuAtivo, history, submodulos, submenus }) => {
    return () => {
        if (submodulos && submodulos.length === 0) return;
        const submenuAtivo = findSubmenuAtivo({ history, submodulos, submenus });
        setSubmenuAtivo(submenuAtivo);
    };
};

const Header = ({ history }) => {
    const submodulos = usuarioSignal.value.submodulos;
    const { strings } = useContext(SttTranslateHook.I18nContext);
    const classes = useStyles();
    const submenus = makeSubmodulos(history, submodulos);
    const [submenuAtivo, setSubmenuAtivo] = useState(findSubmenuAtivo({ history, submodulos, submenus }));
    const [openSS, setOpenSS] = useState(false);
    const [openMS, setOpenMS] = useState(false);

    if (submodulos && submodulos.length > 0) {
        history?.listen(historyListen({ setSubmenuAtivo, history, submodulos, submenus }));
    }

    return (
        <AppBar position="fixed" className={classes.appBar}>
            <SttHeader
                titulo={global.gConfig.instancia_nome}
                config={global.gConfig}
                opcoesDuvida={{
                    onClickNotasAtualizacao: () => setOpenSS((o) => !o),
                    onClickMateriaisSuporte: () => setOpenMS((o) => !o),
                    strings,
                }}
            />
            <SttSplashScreen
                modulo={global.gConfig.modulo_nome}
                versao={global.gConfig.modulo_versao}
                novidades={global.gConfig.modulo_novidades}
                perfisRBAC={usuarioSignal.value.perfisRBAC}
                open={openSS}
                setOpen={setOpenSS}
            />
            <SttMainNavigationBar config={global.gConfig} titulo={global.gConfig.modulo_nome} submenus={submenus} selectedTab={submenuAtivo} />
            {usuarioSignal.value.isAuthorized && (
                <SttNotificationScreen
                    config={global.gConfig}
                    strings={strings}
                    headers={{ Authorization: `Bearer ${Cache.getAccessToken()}`, 'Content-Type': 'application/json' }}
                    perfisRBAC={usuarioSignal.value.perfisRBAC}
                />
            )}
            <MateriaisSuporte
                strings={strings}
                config={global.gConfig}
                headers={{ Authorization: `Bearer ${Cache.getAccessToken()}`, 'Content-Type': 'application/json' }}
                open={openMS}
                setOpen={setOpenMS}
            />
            <SttHelpdeskShortcut config={global.gConfig} usuario={{ perfisRBAC: usuarioSignal.value.perfisRBAC }} />
        </AppBar>
    );
};

export default Header;
