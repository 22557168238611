import React, { useState, useEffect, useContext } from 'react';
import { Funcionalidade, TeleconsultoriaSpec, TipoUsuario } from '@stt-analise/util';
import { SttTranslateHook } from '@stt-componentes/core';
import Dashboard from '../../../components/Dashboard/Dashboard';

/* @useSignals */
import { usuarioSignal } from 'src/signals/usuario';

const configFiltroSecundario = TeleconsultoriaSpec;

/**
 * RN - Regra de negócio para permitir a pesquisa pelos administradores.
 */
const validarFiltros = (filtros) => {
    if(usuarioSignal.value.rbac.montarAcesso(Funcionalidade.PAGINA_DASHBOARD_TELECONSULTORIA_ESTADUAL).tipoUsuario === TipoUsuario.ADMIN) return true;

    const ignoreKeys = [1];
    const newFiltros = filtros.filter(filtro => !ignoreKeys.includes(filtro.key));

    // Se pelo menos 1 filtro for preenchido.
    const valid = newFiltros.filter(
        (filtro) =>
            (filtro.valor != '') &&
            (filtro.valor != undefined) &&
            !(typeof filtro.valor === 'string' && filtro.valor.includes('null'))
    ).length > 0;
    return valid;
};

function TeleconsultoriaMunicipal() {
    const { strings } = useContext(SttTranslateHook.I18nContext);

    const [filtrosSecundarios, setFiltrosSecundarios] = useState(configFiltroSecundario.filtros);
    const [disabledButton, setDisabledButton] = useState(false);

    useEffect(() => {
        const valid = validarFiltros(filtrosSecundarios);
        setDisabledButton(!valid);
    }, [filtrosSecundarios]);

    return (
        <Dashboard
            title={strings.PaginaInicial.dashboardTeleconsultoria}
            configFiltroSecundario={configFiltroSecundario}
            disabledButton={disabledButton}
            filtrosSecundarios={filtrosSecundarios}
            setFiltrosSecundarios={setFiltrosSecundarios}
        />
    );
}

export default TeleconsultoriaMunicipal;
