/**
 * REGRAS DE NEGÓCIO QUE SÃO APLICADAS NO FRONTEND E BACKEND.
 */
import moment from 'moment';
import _ from 'lodash';
import { FiltroConstants, Funcionalidade } from '..';
import { normalizeFiltrosUI } from '../common/FiltroUtil';
import DatasetDruid from '../transform/DatasetDruid';
moment.updateLocale(moment.locale(), {
  invalidDate: ''
});
export var TipoTabela;
(function (TipoTabela) {
  TipoTabela["TABELA_DINAMICA"] = "pivottable";
  TipoTabela["TABELA_NORMAL"] = "Tabela Normal";
})(TipoTabela || (TipoTabela = {}));
const formatTimestampDruid = value => {
  if (value) return moment(value).format('DD/MM/YYYY - HH:mm:ss');
  return null;
};
const formatDateDruid = value => {
  if (value) return moment(value).format('DD/MM/YYYY');
  return null;
};
/**
 * Aplicados apenas ao Frontend.
 */
const formatProtocoloOnlyFrontend = () => {
  let host = 'meus-exames.telemedicina.ufsc.br'; // para ambiente dev.
  const hostSplit = window.location.host.split('.');
  if (hostSplit.length > 1 && window.location.protocol.includes('https')) {
    host = "meus-exames.".concat(hostSplit.slice(1).join('.'));
  }
  return value => {
    return "<a href=\"https://".concat(host, "/protocolo/").concat(value, "\" target=\"_blank\">").concat(value, "</a>");
  };
};
const formatProtocolo = () => {
  if (typeof window !== 'undefined') {
    return formatProtocoloOnlyFrontend();
  }
  return value => value;
};
const defaultRulesCube = {
  ativoTabelaDados: true,
  permitirPeriodicidadeAgendamento: true,
  funcionalidade: undefined,
  swapAtributos: undefined,
  limitLenFiltrosAdicionados: undefined,
  sempreAgrupado: false,
  propsUI: {
    disabledPrefixGrupo: true,
    atributosEstaticos: undefined,
    pivotState: undefined
  },
  propsFiltroMap: undefined,
  transformations: [],
  filtrosDefault: []
};
const rules = {
  cubo_teleatendimento: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'teleatendimento',
    nome_cubo: 'teleatendimento',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELEATENDIMENTO,
    swapAtributos: {
      data_hora_inicio: 'data_hora_inicio_fmt',
      data_hora_fim: 'data_hora_fim_fmt',
      termo_consentimento_data_hora: 'termo_consentimento_data_hora_fmt'
    },
    limitLenFiltrosAdicionados: 1,
    transformations: [],
    filtrosDefault: [{
      key: 2,
      atributo: 'data_hora_inicio',
      atributosPermitidos: ['data_hora_inicio', 'data_hora_fim', 'termo_consentimento_data_hora'],
      // disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'month').toDate();
        return [start, end];
      }
    }]
  }),
  cubo_pontos: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'pontos',
    nome_cubo: 'pontos',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_PONTOS,
    swapAtributos: {
      __time: 'eq_data_inclusao_fmt',
      eq_data_primeiro_exame: 'eq_data_primeiro_exame_fmt',
      eq_data_primeira_atividade: 'eq_data_primeira_atividade_fmt',
      eq_data_ultima_atividade: 'eq_data_ultima_atividade_fmt'
    },
    limitLenFiltrosAdicionados: 1,
    filtrosDefault: [{
      key: '1',
      atributo: 'eq_situacao',
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Ativo'
    }]
  }),
  cubo_pacs: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'pacs',
    nome_cubo: 'pacs',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_PACS,
    limitLenFiltrosAdicionados: 1,
    transformations: [{
      attributes: ['__time', 'eq_data_primeiro_exame'],
      transformation: formatDateDruid
    }],
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: '__time',
      atributosPermitidos: ['__time'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }]
  }),
  cubo_telediagnostico: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'telediagnostico',
    nome_cubo: 'telediagnostico',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELEDIAGNOSTICO,
    swapAtributos: {
      __time: 'data_exame_fmt',
      data_laudo: 'data_laudo_fmt'
    },
    limitLenFiltrosAdicionados: 3,
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: '__time',
      atributosPermitidos: ['__time', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'exame_valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }, {
      key: new Date().getTime() + 2,
      atributo: 'exame_modalidade',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: ''
    }]
  }),
  cubo_exame_eletroencefalo: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'eletroencefalo',
    nome_cubo: 'eletroencefalo',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_EEG,
    transformations: [{
      attributes: ['data_exame'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_laudo'],
      transformation: formatTimestampDruid
    }],
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_exame',
      atributosPermitidos: ['data_exame', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_exame_dermato: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'dermato',
    nome_cubo: 'dermato',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_DERMATO,
    swapAtributos: {
      data_solicitacao_exame: 'data_solicitacao_exame_fmt',
      data_exame: 'data_exame_fmt',
      data_laudo: 'data_laudo_fmt'
    },
    transformations: [{
      attributes: ['protocolo'],
      transformation: formatProtocolo()
    }, {
      attributes: ['data_exame_invalido'],
      transformation: formatTimestampDruid
    }],
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_laudo',
      atributosPermitidos: ['data_solicitacao_exame', 'data_exame', 'data_laudo', 'data_exame_invalido'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_exame_estomato: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'estomato',
    nome_cubo: 'estomato',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ESTOMATO,
    transformations: [{
      attributes: ['data_solicitacao_exame'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_exame'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_laudo'],
      transformation: formatTimestampDruid
    }],
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_exame',
      atributosPermitidos: ['data_solicitacao_exame', 'data_exame', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_exame_espiro: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'espiro',
    nome_cubo: 'espiro',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ESPIRO,
    swapAtributos: {
      data_laudo: 'data_laudo_fmt',
      data_teste: 'data_teste_fmt',
      data_exame: 'data_exame_fmt'
    },
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_laudo',
      atributosPermitidos: ['data_teste', 'data_exame', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_exame_polissono: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'polissono',
    nome_cubo: 'polissono',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_POLISSONO,
    swapAtributos: {
      data_laudo: 'data_laudo_fmt',
      data_solicitacao_exame: 'data_solicitacao_exame_fmt',
      data_exame: 'data_exame_fmt'
    },
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_exame',
      atributosPermitidos: ['data_solicitacao_exame', 'data_exame', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_exame_eletro: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'eletro',
    nome_cubo: 'eletro',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ECG,
    propsFiltroMap: {
      valido: {
        autocomplete: ['Sim', 'Não']
      }
    },
    transformations: [{
      attributes: ['data_solicitacao'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_exame'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_laudo'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['protocolo'],
      transformation: formatProtocolo()
    }],
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_laudo',
      atributosPermitidos: ['data_solicitacao', 'data_exame', 'data_laudo'],
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: new Date().getTime() + 1,
      atributo: 'valido',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }]
  }),
  cubo_tfd: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'tfd',
    nome_cubo: 'tfd',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TFD,
    filtrosDefault: []
  }),
  cubo_tfd_passagens: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'tfd-passagens',
    nome_cubo: 'tfd_passagens',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TFD_PASSAGENS
  }),
  cubo_funcionario_atividades: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'funcionario-atividades',
    nome_cubo: 'funcionario_atividades',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ATIVIDADES,
    limitLenFiltrosAdicionados: 2,
    filtrosDefault: [{
      key: new Date().getTime(),
      atributo: 'data_acao',
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'week').toDate();
        return [start, end];
      }
    }, {
      key: 2,
      atributo: 'tipo',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: ''
    }],
    transformations: [{
      attributes: ['data_acao'],
      transformation: formatTimestampDruid
    }]
  }),
  cubo_funcionario_alunosativos: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'funcionario-alunosativos',
    nome_cubo: 'funcionarioAlunosativos',
    sempreAgrupado: true,
    permitirPeriodicidadeAgendamento: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO_ALUNOS_ATIVOS,
    propsUI: {
      atributosEstaticos: ['medida_contador'],
      pivotState: {
        aggregatorList: [{
          vals: ['Medidas: Quantidade'],
          aggTitle: 'Quantidade',
          aggregatorName: 'Integer Sum'
        }]
      }
    },
    transformations: [],
    filtrosDefault: []
  }),
  cubo_funcionario: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'funcionario',
    nome_cubo: 'funcionario',
    permitirPeriodicidadeAgendamento: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_FUNCIONARIO,
    propsUI: {
      disabledPrefixGrupo: true
    },
    transformations: [],
    filtrosDefault: []
  }),
  cubo_dashboard_teleconsultoria_atrasadas: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'dashboard',
    nome_cubo: 'dashboardTeleconsultoriaAtrasadas',
    permitirPeriodicidadeAgendamento: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS_EM_ABERTO,
    limitLenFiltrosAdicionados: 4,
    propsFiltroMap: {
      atrasado: {
        autocomplete: ['Sim', 'Não']
      }
    },
    propsUI: {
      atributosEstaticos: ['medida_contador'],
      pivotState: {
        aggregatorList: [{
          vals: ['Medidas: Quantidade de teleconsultorias'],
          aggTitle: 'Soma das teleconsultorias',
          aggregatorName: 'Integer Sum'
        }]
      }
    },
    transformations: [],
    filtrosDefault: [{
      key: 2,
      disabled: true,
      habilitarRegraVinculo: true,
      atributo: 'rede_teleconsultoria',
      rotulo: 'Rede teleconsultoria',
      tipoComponente: 'IN_SELECT',
      valor: ''
    }, {
      key: 3,
      disabled: true,
      habilitarRegraVinculo: true,
      atributo: 'estado',
      rotulo: 'Estado',
      tipoComponente: 'IN_SELECT',
      valor: ''
    }, {
      key: 4,
      disabled: true,
      habilitarRegraVinculo: true,
      atributo: 'cidade',
      rotulo: 'Município',
      // dependsOn: ['estado'],
      tipoComponente: 'IN_SELECT',
      valor: ''
    }, {
      key: 5,
      disabled: true,
      habilitarRegraVinculo: true,
      atributo: 'atrasado',
      rotulo: 'Atrasado',
      tipoComponente: 'IN_SELECT',
      valor: 'Sim'
    }]
  }),
  cubo_teleconsultoria: Object.assign(Object.assign({}, defaultRulesCube), {
    projeto: 'teleconsultoria',
    nome_cubo: 'teleconsultoria',
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_TELECONSULTORIAS,
    limitLenFiltrosAdicionados: 3,
    propsFiltroMap: {
      situacao_tele: {
        autocomplete: ['Invalidada', 'Rascunho', 'Encaminhada para telerregulação / Aguardando resposta', 'Devolvida', 'Encaminhada para o(a) teleconsultor(a) / Aguardando resposta', 'Aguardando resposta complementar', 'Resposta sendo finalizada', 'Resposta em edição', 'Respondida', 'Aguardando tréplica / Aguardando resposta', 'Tréplica respondida', 'Avaliada']
      },
      foi_devolvida: {
        autocomplete: ['Sim', 'Não', 'Não se aplica']
      },
      eh_valida: {
        autocomplete: ['Sim', 'Não']
      }
    },
    transformations: [{
      attributes: ['data_teleconsultoria'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_solicitacao'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_atendimento'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_leitura'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_avaliacao'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_hora_devolucao'],
      transformation: formatTimestampDruid
    }, {
      attributes: ['data_invalidacao'],
      transformation: formatTimestampDruid
    }],
    filtrosDefault: [{
      key: 2,
      atributo: 'data_solicitacao',
      atributosPermitidos: ['data_solicitacao', 'data_teleconsultoria', 'data_atendimento', 'data_leitura', 'data_avaliacao', 'data_hora_devolucao', 'data_invalidacao'],
      // disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.BETWEEN_INCLUSIVE_TIMESTAMP_MS,
      valor: () => {
        const end = moment().toDate();
        const start = moment().subtract(1, 'month').toDate();
        return [start, end];
      }
    }, {
      key: 3,
      atributo: 'eh_valida',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: 'Sim'
    }, {
      key: 4,
      atributo: 'foi_devolvida',
      disabled: true,
      tipoComponente: FiltroConstants.TIPO_FILTRO.IN_SELECT,
      valor: ''
    }]
  }),
  cubo_dashboard_teleconsultoria: Object.assign(Object.assign({}, defaultRulesCube), {
    ativoTabelaDados: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_DASHBOARD_TELECONSULTORIA
  }),
  cubo_itinerario_exames: Object.assign(Object.assign({}, defaultRulesCube), {
    ativoTabelaDados: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_EXAMES
  }),
  cubo_itinerario_solicitacoes: Object.assign(Object.assign({}, defaultRulesCube), {
    ativoTabelaDados: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_SOLICITACOES
  }),
  cubo_itinerario_pontos: Object.assign(Object.assign({}, defaultRulesCube), {
    ativoTabelaDados: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_PONTOS
  }),
  cubo_itinerario_cidades: Object.assign(Object.assign({}, defaultRulesCube), {
    ativoTabelaDados: false,
    funcionalidade: Funcionalidade.FUNC_TABELA_DADOS_CUBO_ITINERARIO_CIDADES
  })
};
export const getFuncionalidade = cubeName => {
  var _a;
  return (_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.funcionalidade;
};
const getRules = cubeName => {
  if (!cubeName) return null;
  return rules[cubeName] || defaultRulesCube;
};
export const getProject = cubeName => {
  const {
    projeto,
    nome_cubo
  } = getRules(cubeName);
  return {
    projeto,
    nome_cubo
  };
};
export const getDefaultFiltros = cubeName => {
  var _a;
  let filtros = normalizeFiltrosUI(((_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.filtrosDefault) || []);
  return filtros;
};
export const permitePeriodicidade = cubeName => {
  var _a;
  return !!((_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.permitirPeriodicidadeAgendamento);
};
export const getPropsFiltroMap = cubeName => {
  var _a;
  return ((_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.propsFiltroMap) || {};
};
/**
 * Filtra os cubos de acordo com sua permissão de acesso.
 *
 * @param cubos Todos os cubos para tabela de dados
 * @returns apenas cubos permitidos
 */
export const filtrarCubosPermitidos = function () {
  let cubos = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  let rbac = arguments.length > 1 ? arguments[1] : undefined;
  return cubos.filter(cubo => {
    const rule = getRules(cubo.dataset);
    if (!(rule === null || rule === void 0 ? void 0 : rule.ativoTabelaDados)) return false;
    // Nem todos os cubos possuem permissão registrada.
    const funcionalidade = rule === null || rule === void 0 ? void 0 : rule.funcionalidade;
    if (funcionalidade) {
      return rbac.montarAcesso(funcionalidade).temPermissao;
    }
    // Os demais cubos ficam desabilitados. Ou seja, obrigatório que tenha permissão.
    return false;
  });
};
export const naoExisteFiltros = function () {
  let filtros = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  return !(filtros.length > 0);
};
/**
 * Regras principais para executar o filtro.
 */
export const validarExecutarConsulta = cubo => {
  const rule = getRules(cubo);
  if (!rule) return false;
  // Se precisa de filtro obrigatório, validar que todos os campos precisam estar preenchidos.
  return cubo;
};
/**
 * Recuperar configurações da tela de acordo com o cubo selecionado.
 */
export const getPropsUI = cubeName => {
  var _a;
  return (_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.propsUI;
};
export const validarLimiteDataset = data => {
  const LIMIT = 10000;
  const count = data.length - 1;
  const valid = count <= LIMIT;
  return [valid, count];
};
/**
 * Aplicação de transformações para frontend ou exportação via API.
 * Regra de negócio aplicado a saida de dados.
 */
export const formatDataset = (cubeName, dataset) => {
  var _a;
  // Inverter Header, possiveis swap de atributos
  dataset[0] = _invertAtributos(cubeName, dataset[0]);
  const builder = new DatasetDruid(dataset);
  const transformationsFn = ((_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.transformations) || [];
  return builder.applyTransformations(transformationsFn).build();
};
/**
 * Adiciona algumas regras nos atributos antes de ir para o Servidor.
 *
 * @param cubo nome do dataset no Apache Druid.
 * @param dimensoes colunas do dataset no Apache Druid.
 * @returns
 */
const _normalizeAtributos = function (cubo) {
  let dimensoes = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  // RN01: Regra para trocar o atributo selecionado por outro atributo
  // @TODO Adicionar no metadados a divisão entre Atributo e Filtro.
  const swapAtributos = getRules(cubo).swapAtributos;
  if (!swapAtributos) return dimensoes;
  return dimensoes.map(attr => swapAtributos[attr] || attr);
};
export const normalizeDimensoesAndMedidas = function (cubo) {
  let atributosSelecionados = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  let metaAtributos = arguments.length > 2 ? arguments[2] : undefined;
  let agrupado = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : false;
  const atributosDoTipoMedida = metaAtributos.filter(meta => meta.grupo === 'Medidas').map(meta => meta.atributo);
  let medidas, dimensoes;
  if (agrupado) {
    medidas = _.intersection(atributosSelecionados, atributosDoTipoMedida) || [];
    dimensoes = _.difference(atributosSelecionados, atributosDoTipoMedida) || [];
  } else {
    dimensoes = atributosSelecionados || [];
    medidas = [];
  }
  const nDimensoes = _normalizeAtributos(cubo, dimensoes);
  return [nDimensoes, medidas];
};
export const isSempreAgrupado = cubo => {
  var _a;
  return ((_a = getRules(cubo)) === null || _a === void 0 ? void 0 : _a.sempreAgrupado) || false;
};
/**
 * Inverte as regras adicionadas pela função _normalizeAtributos, executa na resposta vinda do servidor.
 *
 * @param cubeName nome do dataset no Apache Druid.
 * @param atributosSelecionados colunas do dataset no Apache Druid.
 * @returns
 */
const _invertAtributos = function (cubeName) {
  let atributosSelecionados = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  const swapAtributos = getRules(cubeName).swapAtributos;
  if (!swapAtributos) return atributosSelecionados;
  const invertAtributos = _.invert(swapAtributos);
  return atributosSelecionados.map(attr => invertAtributos[attr] || attr);
};
/**
 * Renomear cabeçalho de um dataset.
 */
export const renameHeaders = function (headers, metaAtributos) {
  let disabledPrefixGrupo = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;
  let template = metaAtributoEncontrado => "".concat(metaAtributoEncontrado.grupo, ": ").concat(metaAtributoEncontrado.rotulo);
  if (disabledPrefixGrupo) {
    template = metaAtributoEncontrado => metaAtributoEncontrado.rotulo;
  }
  const newHeaders = headers.map(columname => {
    const metaAtributoEncontrado = metaAtributos.filter(metaAtributo => metaAtributo.atributo == columname)[0];
    return metaAtributoEncontrado ? template(metaAtributoEncontrado) : columname;
  });
  return newHeaders;
};
export const renameHeadersByCubo = (cubeName, headers, metaAtributos) => {
  var _a;
  const disabledPrefixGrupo = ((_a = getPropsUI(cubeName)) === null || _a === void 0 ? void 0 : _a.disabledPrefixGrupo) || false;
  return renameHeaders(headers, metaAtributos, disabledPrefixGrupo);
};
export const disabledFilter = (cubeName, filtrosAdicionados) => {
  var _a;
  if (!cubeName || cubeName === '') return true;
  const limitLenFiltrosAdicionados = ((_a = getRules(cubeName)) === null || _a === void 0 ? void 0 : _a.limitLenFiltrosAdicionados) || -1;
  if (filtrosAdicionados && filtrosAdicionados.length === limitLenFiltrosAdicionados) return true;
  return false;
};