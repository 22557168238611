import React, { useState } from 'react';
import { Route, Switch, Redirect, BrowserRouter } from 'react-router-dom';

import Interno from './pages/interno';
import { Error } from './pages/error/Error';
import Authenticate from './pages/authenticate/Authenticate';

const App = () => {
    const [loaded, setLoaded] = useState(false);
    return (
        <>
            <Authenticate onLoaded={setLoaded} />
            {loaded && (
                <BrowserRouter basename={`/${global.gConfig.basename}`}>
                    <Switch>
                        <Route exact path="/" render={() => <Redirect to="/apps/menu-principal" />} />
                        <Route exact path="/apps" render={() => <Redirect to="/apps/menu-principal" />} />
                        <Route path="/apps" component={Interno} />
                        <Route component={Error} />
                    </Switch>
                </BrowserRouter>
            )}
        </>
    );
};

export default App;
