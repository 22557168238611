import React from 'react';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';

const ExportarDadosFrontendParaPDF = () => {
    const viewElement = document.querySelector('td.pvtOutput');
    const table = viewElement?.firstElementChild;
    const temMetricas = document.querySelector('.MuiTypography-root.MuiListItemText-primary.MuiTypography-body2.MuiTypography-displayBlock');
    if (!temMetricas) {
            const elementosAxisLabel = table.querySelectorAll('th.pvtAxisLabel');
                elementosAxisLabel.forEach((elemento, indice) => {
                    if (elemento.getAttribute('rowspan') === '2') {
                        elemento.setAttribute('rowspan', '1');
                    }
                });
            }
    try {
        const viewElement = document.querySelector('td.pvtOutput');
        const table = viewElement?.firstElementChild;
        if (!table) {
            console.error("Tabela não encontrada para exportação.");
            return;
        }
        // Criação do documento PDF
        const doc = new jsPDF({
            orientation: "landscape",
            unit: "pt",
            format: "a4",
        });
        const totalPagesExp = "{total_pages_count_string}";
        doc.setFont("helvetica", "bold");
        doc.setFontSize(12);
        var larguraPagina = doc.internal.pageSize.getWidth();
        var alturaPagina = doc.internal.pageSize.getHeight();
        var margem = 20;
        const titulo = "ESTADO DE SANTA CATARINA\nSECRETARIA DE ESTADO DA SAÚDE\nSUPERINTENDÊNCIA DE SERVIÇOS ESPECIALIZADOS E REGULAÇÃO\nCENTRAL ESTADUAL DE TELEMEDICINA\n\nRelatórios de Dados ";
        autoTable(doc, {
            html: table,
            useCss: true,
            margin: { top: 130 },
            horizontalPageBreak: true,
            horizontalPageBreakRepeat: 0,
            didDrawPage: (data) => {
                // Header
                doc.setFontSize(12);
                doc.setFont("helvetica", "bold");
                doc.text(titulo, larguraPagina / 2, 30, { align: "center" });
                // Paginação
                if (data.pageNumber >= 2) {
                    let textoRodape = "Página " + data.pageNumber;
                    if (typeof doc.putTotalPages === "function") {
                        textoRodape = textoRodape + " de " + totalPagesExp;
                    }
                    doc.text(
                        textoRodape,
                        larguraPagina - margem - 100,
                        alturaPagina - 10
                    );
                }
            },
        });
        // Substituir o marcador de total de páginas pelo valor real
        if (typeof doc.putTotalPages === "function") {
            doc.putTotalPages(totalPagesExp);
        }
        doc.save("tabela-de-dados.pdf");
    } catch (error) {
        console.error('Erro ao exportar os dados para PDF: ', error);
    }
};


export default ExportarDadosFrontendParaPDF;
